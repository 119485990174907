.hidden {
  display: none;
  visibility: hidden;
}

#header {
	background: #fff; border-bottom: 1px solid $cBlue; position: relative; z-index: 100;
	// Logo
	.logo {
		padding: 20px 0 0 4px; @include fh(0em, 0em); float: left;
		img {@include image();}
		strong {display: none;}
	}
}

// Bottom button
#fixed-btn {
	position: fixed; right: 18px; bottom: 0;
	a.btn-orange {
		@include borderRadius(0); background: darken(#e95241, 4%); padding: 0 24px; @include fh(16px, 46px); height: 46px;
		&:hover {background: darken(#e95241, 12%);}
	}
}

// Hlavni menu
p#menu-toggle {
	float: right; display: none; @include fh(14px, 60px);
	a {
		@include textDec(0,0);
		display: block; position: relative; color: $cBlueDark; padding: 0 64px 0 0; text-transform: uppercase; font-weight: bold;
		i.ico {right: 12px; top: 50%; margin-top: -17px;}
		i.focus {display: none;}
		&:hover {
			i.ico {display: none;}
			i.focus {display: block;}
		}
	}
	&.open a {
		i.ico {display: none;}
		i.focus {display: block;}
	}
}
#main-menu {
	float: right;
	> ul {
		> li {
			@include fh(14px, 80px);
			float: left; text-align: center; margin: 0 4px; text-transform: uppercase;
			> a {
				@include textDec(0,0);
				display: block; height: 80px; color: $cBlueDark; padding: 0 12px; position: relative; font-weight: 500;
				&:before {content: ""; position: absolute; display: block; width: 90%; left: 5%; bottom: -2px; height: 3px; background: $cGreen; display: none; z-index: 90;}
			}
			&.active > a, > a:hover {
				&:before {display: block;}
			}
			// Objednavka
			&.li-order > a {background: $cLineLight;}
			// Facebook
			&.li-fb {
				> a {
					position: relative; width: 50px;
					> span > span {display: none;}
					i.ico {left: 50%; margin-left: -14px; top: 50%; margin-top: -14px; @include opacity(0.7);}
					&:hover i.ico {@include opacity(1);}
				}
			}
			// Separ
			&.li-separ {
				position: relative;
				margin-left: 4px;
				&:before {
					position: absolute;
					left: -2px;
					top: 25%;
					height: 50%;
					width: 1px;
					background: rgba(0,0,0,0.2);
					display: block;
					content: "";
				}
			}
			// Basket
			&.li-basket {
				> a {
					position: relative;
					width: 50px;
					> span {
						display: block;
						position: absolute;
						right: 50%;
						top: 50%;
						margin-right: -18px;
						margin-top: -20px;
						@include borderRadius(50%);
						background: $cGreen;
						color: #fff;
						@include fh(11px, 16px);
						min-width: 16px;
						padding: 0 4px;
						text-align: center;
						z-index: 10;
						font-family: Arial, sans-serif;
					}
					i.ico {
						left: 50%;
						margin-left: -11px;
						top: 50%;
						margin-top: -14px;
					}
					&:hover > span {
						background: $cBlueDark;
					}
				}
			}
			// Language
			&.li-language {
				padding: 0 6px;
				@include fh(0em, 80px);
				// Parents
				> a, > span:not(.clear) {
					padding: 0 5px;
					display: block;
					float: left;
					position: relative;
					width: 36px;
					height: 80px;
				}
				img {
					display: block;
					width: 26px;
					height: 26px;
					position: absolute;
					top: 50%;
					left: 50%;
					margin: -13px 0 0 -13px;
					@include borderRadius(50%);
				}
				> a {
					img {
						@include opacity(0.6);
						@include grayscale();
						@include transition(all .25s ease);
					}
					&:hover img {
						@include opacity(1);
						@include grayscale(0);
					}
				}
			}
		}
	}
}

// Hlavni obsah
.main-outer {background: $bg url('../image/bg.png') 50% 0 no-repeat; padding: 0; position: relative;}
.main-inner {
	background: #fff url('../image/main-bg.png') 50% 100% no-repeat; padding: 24px 26px; @include borderRadius(6px);
	h1.main-title {
		color: $cOrange; @include fh(24px); font-weight: 400; margin-bottom: 20px;
		i.icoCoffe {position: static; display: inline-block;}
		&.bold {font-weight: 700;}
		&.green {color: $cGreen;}
	}
	h2.main-title {
		color: $cBase; @include fh(22px); font-weight: 500; margin: 30px 0 16px;
	}
	.vertical-inner {
		margin-top: 30px; margin-bottom: 24px;
	}
}
.hr, .hr-dark {background: $cLineLight; border: none; height: 1px; width: 100%; margin: 20px 0; @include fh(0em); overflow: hidden;}
.hr-dark {background: $cLine;}

// Bottom
.bottom {
	margin: 30px 0 20px;
	.boxes {
		position: relative; margin: 0 -12px; text-align: center;
		> div {float: left; width: 50%; padding: 0 12px;}
		h2 {
			position: relative; font-weight: 400;
			span {
				@include borderRadius(12px); @include fh(16px, 34px);
				width: 88%; display: block; margin-left: 6%; background: $cOrange; color: #fff; padding-top: 1px; position: relative; z-index: 10;
			}
			&:before {
				@include fh(0em);
				content: ""; position: absolute; left: 0; top: 50%; background: $cOrange; height: 2px; overflow: hidden; display: block; width: 100%; margin-top: -1px;
			}
		}
		p {
			margin: 16px 0;
			img {@include image();}
		}
	}
}

// Kontakty - bublina
.bubble-contact {
	@include fh(14px);
	position: fixed; right: 10px; bottom: 10px; width: 200px; height: 92px; background: url('../image/bubble-contact.png') 0 0 no-repeat; color: #fff; text-align: left; z-index: 190;
	ul {
		padding: 18px 0 0 18px;
		li {
			position: relative; padding: 3px 0; padding-left: 18px;
			a {@include textDec(0,1); color: #fff;}
			i.ico {left: 0; top: 50%; margin-top: -7px;}
		}
	}
}

/**
 * Cena
 */
#basket {
	@include borderRadius(16px); @include fh(16px);
	background: #e95241 url('../image/basket-bg.png') 50% 100% no-repeat; color: #fff; position: fixed; top: 10px; right: 220px; width: 200px; padding: 12px 18px; z-index: 200;
	p {padding: 6px 0;}
	strong {display: block;}
	a {color: #fff; @include textDec(1,0);}
}


/**
 * Paticka
 */
#footer {
	color: #d6e3eb; padding-top: 22px; padding-bottom: 22px;
	.boxes {
		position: relative; margin: 0 -12px;
		> div {padding: 0 12px;}
		> .float-left {
			a {color: #fff; @include textDec(1,0);}
			p {
				margin: 8px 0;
				&.bepositive {
					a, img {display: block;}
					a:hover {@include opacity(0.6);}
				}
			}
			ul.list {padding: 12px 0;}
		}
		> .float-right {
			h2 {font-weight: normal; @include fh(14px); color: #fff; margin: 8px 0;}
			form {
				margin: 18px 0;
				p {
					@include borderRadius(18px); padding: 4px;
					border: 1px solid #fff;
					input.footer-inp {
						@include _font();
						display: block; float: left; border: none; background: none; color: #fff; text-indent: 8px; width: 160px; height: 28px; font-size: 14px;
						&:focus {outline: none;}
					}
					button.btn {height: 28px; width: 110px; float: right; padding: 0;}
				}
			}
			ul.list {
				padding-top: 6px;
				li {padding: 2px 0;}
				a {color: #d6e3eb; @include textDec(0,1);}
			}
		}
	}
	ul.list {
		margin: 8px 0;
		li {padding: 1px 0;}
	}
}


/**
 * Breadcrumbs
 */
#breadcrumbs {
	@include fh(0em); @include borderRadius(8px);
	margin: 30px 40px; background: $cLineLight; padding: 10px 0 9px;
	ul {
		li {
			@include fh(14px);
			display: block; float: left; position: relative; padding: 0 16px; font-weight: 700; color: $cBlue;
			a, span {color: $cGreen;}
			a {@include textDec(0,1);}
			&:before {
				width: 8px; height: 14px; background: url('../image/ico.png') -108px 0 no-repeat;
				content: ""; display: block; position: absolute; left: -4px; top: 50%; margin-top: -7px;
			}
			&:first-child {&:before {display: none;}}
		}
	}
}


/**
 * Branch
 */
.branch {
	display: table;
	border: 1px solid $cBlue;
	background: $cLineLight;
	width: 100%;
	margin: 0 0 20px;
	> div, > p {
		display: table-cell;
		margin: 0;
		vertical-align: middle;
		padding: 12px;
	}
	// right
	.right {
		text-align: right;
	}
	// Content
	.content {
		h3 {
			@include fh(20px, 1.2em);
			font-weight: 500;
		}
		p { margin: 8px 0 0; }
		.open-hours {
			color: lighten($cBase, 20%);
			strong, span {
				color: $cBase;
			}
			span {
				font-weight: 500;
			}
		}
	}
}


/**
 * Box
 */
.box {
	border: 1px solid $cBlue;
	padding: 12px 14px;
	margin: 0 0 20px;
	// Fa
	&.box-fa {
		position: relative;
		min-height: 40px;
		padding-left: 80px;
		.fa {
			position: absolute;
			@include fh(30px, 1em);
			display: block;
			top: 50%;
			margin-top: -15px;
			left: 26px;
			color: $cOrange;
			width: 30px;
			text-align: center;
		}
	}
}



/**
 * Filelist
 */
.filelist {
	margin: 10px 0;
	.item {
		border-top: 1px solid $cLineLight;
		padding: 12px 0 8px;
		h3 {
			@include fh(16px);
			font-weight: bold;
			color: $cOrange;
			.fa {
				font-size: 0.6em;
			}
			a {
				@include textDec(1,0);
			}
		}
	}
	// List of properties
	ul {
		padding: 8px 0;
		li {
			padding-bottom: 2px;
			position: relative;
			padding-left: 12px;
			&:before {
				content: "-";
				display: inline-block;
				margin-right: 6px;
				font-weight: 500;
				color: $cBase;
			}
			&.check {
				color: $cGreen;
			}
			&.uncheck {
				color: $cRed;
			}
			span {
				color: $cOrange;
				font-weight: 500;
			}
		}
	}
	// Buttons
	p.links {
		padding-top: 0 !important;
		> * {
			margin-left: 4px;
			margin-right: 4px;
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
}


/**
 * Row
 */
.row {
	margin-left: -15px;
	margin-right: -15px;
	position: relative;
	.col-half {
		padding-left: 15px;
		padding-right: 15px;
		float: left;
		width: 50%;
	}
}


/**
 * Reasons
 */
.reasons {
	margin: 12px 40px 20px;
	.item {
		text-align: left;
		display: table;
		float: left;
		width: 33.33333%;
		position: relative;
		padding: 8px 12px;
		p, h2 {
			display: table-cell;
			vertical-align: middle;
			table-layout:fixed;
		}
		p {
			width: 50px;
		}
		.fa {
			@include borderRadius(50%);
			height: 50px;
			width: 50px;
			color: #fff;
			background: $cGreen;
			@include fh(24px, 50px);
			text-align: center;
		}
	}
	h2 {
		@include fh(16px);
		font-weight: bold;
		padding-left: 10px;
		margin-bottom: 6px;
	}
}

/* Drobnosti */
.pointer {
	cursor: pointer;
}