$__Header-Height: 80px;
$__Header-Height-Fixed: 50px;
body {
	padding-top: $__Header-Height;
	@media (max-width: $screen-lg-max) {
		&.responsive-content {
			min-width: 320px !important;
		}
	}
}


@media (max-width: $screen-lg-max) {
	.wrap {
		min-width: 320px !important;
	}
}

// Top bar
#top-bar {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	z-index: 110;
	background-color: darken($cWhite, 4%);
	.wrap {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		height: 34px;
		padding-right: 12px;
	}
	.languages {
		@include fh(12px, 1.3em);
		font-weight: bold;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		a {
			color: $cBase;
			display: flex;
			align-items: center;
		}
		li {
			&.active {
				a {
					color: $cOrange;
					text-decoration: underline;
				}
			}
			span {
				margin-right: 6px;
			}
			+ li {
				margin-left: 24px;
			}
		}
	}
}

#header-beta {
	background: #fff;
	border-bottom: 1px solid $cBlue;
	position: absolute;
	left: 0;
	top: 34px;
	right: 0;
	text-align: center;
	z-index: 100;
	min-height: $__Header-Height;

	.flex-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	// Logo
	.logo {
		padding: 0 20px 0 4px;
		position: relative;
		z-index: 90;
		@include fh(0em, 0em);
		img {
			@include image();
		}
		strong {
			display: none;
		}
		a {
			display: block;
		}
	}
	// Text
	.contact-data {
		text-align: center;
		@include fh(18px);
		font-weight: 500;
		p {
			display: inline-block;
			white-space: nowrap;
			margin: 2px 12px;
			i {
				display: inline-block;
				margin-right: 4px;
			}
			&.number {
				top: 1px;
				position: relative;
			}
		}
	}
	// Right
	.right {
		position: relative;
		z-index: 90;
		//width: 204px;
		text-align: center;
		border-right: 1px solid $cLine;
		@include fh(14px);
		display: flex;
		> * {
			border-left: 1px solid $cLine;
			a {
				display: block;
				position: relative;
				color: $cBlue;
				height: $__Header-Height;
				padding: 18px 16px 0;
				@include transition(all .25s ease);
				@include textDec(0, 0);
				&:hover {
					background: $cLineLight;
				}
				white-space: nowrap;
			}
		}
		&.menu-open > * a {
			border-bottom: 1px solid $cLine;
		}
	}
	// Basket
	.basket {
		a {
			padding-top: 12px;
		}
		&.highlighted {
			border-color: darken($cGreen, 12%);
			+ p {
				border-left-color: darken($cGreen, 12%);
			}
			a {
				background-color: $cGreen;
				color: #fff;
				&:hover {
					background-color: lighten($cGreen, 5%);
				}
			}
		}
		.fa {
			display: block;
			@include fh(24px);
		}
		span {
			display: block;
			padding-top: 2px;
		}
		.price {
			color: $cGreen;
			font-weight: 700;
		}
	}

	// Menu handle
	.menu-handle {
		text-transform: uppercase;
		font-weight: 500;
		line-height: 48px;
		a {
			display: flex;
			position: relative;
			color: $cOrange;
			span {
				font-weight: $bold;
				margin-right: 10px;
			}
		}
		.fa {
			@include fh(24px, inherit);
			transform: translateY(-1px);
		}
		.fa-close {
			display: none;
		}
	}
	.menu-open .menu-handle {
		a {
			color: $cOrange;
		}
		.fa {
			display: none;
		}
		.fa-close {
			display: inline-block;
		}
	}

	&.theme-fixed {
		min-height: $__Header-Height-Fixed;
		box-shadow: 0 0 20px rgba(#000,0.2);
		top: 0;
		position: fixed;
		// Logo
		.logo {
			img {
				width: auto;
				max-height: 26px;
			}
		}

		.contact-data {
			@include fh(16px);
		}
		// Right
		.right {
			> * {
				a {
					height: $__Header-Height-Fixed;
					padding: 0 14px 0;
					i {
						position: relative;
						left: auto;
						top: auto;
						right: auto;
						bottom: auto;
						line-height: $__Header-Height-Fixed;
						margin-top: 0;
					}
				}
			}
			:not(.menu-handle) a {
				span {
					display: none;
				}
			}
		}
		#main-menu-beta {
			top: $__Header-Height-Fixed;
		}
	}
}

@media (max-width: $screen-lg-max) {
	#header-beta {
		.logo {
			padding-left: 15px;
		}
		.right {
			border-right-width: 0;
		}
	}
}

@media (max-width: $screen-md-max) {
	#header-beta {
		.contact-data {
			@include fh(16px);
			.email {
				display: none;
			}
		}
	}
}

@media (max-width: $screen-xs-max) {
	#header-beta {
		.logo {
			padding-right: 0;
		}
		.contact-data {
			@include fh(14px !important);
			p i {
				display: none;
			}
		}
		&:not(.theme-fixed) .contact-data {
			display: none;
		}
	}
}

@media (max-width: $screen-xxs-max) {
	#header-beta {
		.logo {
			img {

			}
		}
		.right .menu-handle a span {
			display: none;
		}
	}
}

/**
 * Main menu
 */
$__MenuColCnt: 5;
#main-menu-beta {
	position: absolute;
	z-index: 110;
	top: $__Header-Height;
	left: 0;
	right: 0;
	background: #fff;
	display: none;
	border-bottom: 1px solid $cLine;
	padding: 30px 0;
	@include fh(0em);
	@include boxShadow(0 4px 6px rgba(0, 0, 0, 0.15));
	ul {
	}
	li {
		display: inline-block;
		@include fh(18px);
		text-transform: uppercase;
		border-right: 1px solid $cLine;
		&:first-child {
			border-left: 1px solid $cLine;
		}
	}
	a {
		display: block;
		padding: 86px 16px 20px;
		min-width: 166px;
		color: $cBlue;
		position: relative;
		@include transition(all .25s ease);
		@include textDec(0, 0);
		span {
			color: $cBlueDark;
			font-weight: 500;
		}
		&:hover {
			color: $cGreen;
			background: $cLineLight;
		}
		.fa {
			width: 50px;
			height: 50px;
			@include fh(48px, 50px);
			position: absolute;
			left: 50%;
			top: 20px;
			margin-left: -25px;
		}
	}
	li.active a {
		background: $cLineLight;
	}
}

@media (max-width: $screen-lg-max) {
	#main-menu-beta {
		padding: 10px 0;
		li {
			width: 100% / $__MenuColCnt;
			&:first-child {
				border-left: none;
			}
			&:last-child {
				border-right: none;
			}
			a {
				min-width: 0;
			}
		}
	}
}

@media (max-width: $screen-md-max) {
	#main-menu-beta {
		li {
			display: block;
			padding: 1px 0;
			width: auto;
			border: none;
			a {
				min-width: 0;
				padding: 10px 0;
				@include fh(18px);
				.fa {
					display: inline-block;
					position: relative;
					left: auto;
					top: auto;
					margin-left: 0;
					width: auto;
					height: auto;
					margin-right: 4px;
					@include fh(18px);
				}
			}
		}
	}
}

/**
 * Footer
 */
#footer-beta {
	color: #fff;
	padding-top: 30px;
	padding-bottom: 30px;
	a {
		color: #fff;
		@include textDec(1, 0);
	}
	.boxes {
		position: relative;
		margin: 0 -12px;
		> div {
			padding: 0 12px;
		}
		> .pull-left {
			width: 28%;
			p {
				margin: 14px 0;
				&.bepositive {
					a, img {
						display: block;
					}
					a:hover {
						@include opacity(0.6);
					}
				}
			}
		}
		> .pull-right {
			width: 72%;
			.csscolumns & ul.list {
				-webkit-column-count: 3; /* Chrome, Safari, Opera */
				-moz-column-count: 3; /* Firefox */
				column-count: 3;
				li {
					float: none;
					width: auto;
				}
			}
		}
	}
	ul.list {
		margin: 8px 0;
		li {
			padding: 5px 0;
			float: left;
			width: 33.33333%;
		}
		a {
			color: $bg;
		}
	}
}

@media (max-width: $screen-md-max) {
	#footer-beta {
		.csscolumns & .boxes > .pull-right ul.list {
			-webkit-column-count: 2; /* Chrome, Safari, Opera */
			-moz-column-count: 2; /* Firefox */
			column-count: 2;
		}
	}
}

@media (max-width: $screen-sm-max) {
	#footer-beta {
		.boxes {
			text-align: center;
			> * {
				float: none !important;
				width: auto !important;
			}
			> .pull-left {
				padding-bottom: 14px;
				p.bepositive img {
					display: inline-block;
				}
			}
			.csscolumns & > .pull-right ul.list {
				-webkit-column-count: 1; /* Chrome, Safari, Opera */
				-moz-column-count: 1; /* Firefox */
				column-count: 1;
			}
		}
	}
}
