$icomoon-font-path: "../fonts" !default;

$cico-coffee-cup: "\e900";
$cico-stopwatch: "\e901";



@font-face {
	font-family: 'cico';
	src:  url('#{$icomoon-font-path}/cico.eot?k18wnp');
	src:  url('#{$icomoon-font-path}/cico.eot?k18wnp#iefix') format('embedded-opentype'),
	url('#{$icomoon-font-path}/cico.ttf?k18wnp') format('truetype'),
	url('#{$icomoon-font-path}/cico.woff?k18wnp') format('woff'),
	url('#{$icomoon-font-path}/cico.svg?k18wnp#cico') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="cico-"], [class*=" cico-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'cico' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.cico-coffee-cup {
	&:before {
		content: $cico-coffee-cup;
	}
}
.cico-stopwatch {
	&:before {
		content: $cico-stopwatch;
	}
}

