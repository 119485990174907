// Top slide
.hp-slide {
	text-align: center; padding: 0 20px;
	h1 {
		color: $cOrange; @include fh(30px); font-weight: 400; margin: 0 20px;
		span {display: block;}
	}
	> p {margin: 20px 0;}
	img {@include image();}
	// Boxes
	.boxes {
		@include fh(0em); text-align: center; margin: 20px 0 0;
		p {
			display: inline-block; margin: 0 10px;
			img {display: block; @include borderRadius(80px); border: 5px solid #fff;}
		}
	}
	// Duvody
	ul.reasons {
		text-align: left; @include fh(16px); padding: 20px 60px; margin: 20px 0; font-weight: 500;
		li {
			float: left; width: 33.333333%; border-left: 1px solid $cLine; padding: 8px 34px 8px 90px; position: relative;
			&:first-child {border-left: none;}
			i.ico {left: 34px; top: 50%; margin-top: -18px;}
		}
	}
	// Button
	p.btn-wrap {margin: 20px 0; padding: 14px 0;}
}

// Recenze
@mixin reviewSepar() {content: ""; display: block; position: absolute; top: 50%; margin-top: -136px; background: url('../image/review-separ.png') 0 0 no-repeat; width: 102px; height: 273px;}
.review-outer {
	background: #fff; padding: 20px; @include borderRadius(4px); border-bottom: 1px solid $cLine;
	.review-inner {
		text-align: center;
		> .review {
			float: left; width: 33.333333%; padding: 4px 30px; position: relative;
			&:first-child {
				&:before {@include reviewSepar(); right: -102px;}
			}
			&:nth-child(2) {
				&:before {@include reviewSepar(); right: 0; @include rotate(180deg);}
			}
		}
		p.thumb img {display: inline-block; border: 1px solid #d6e3eb; padding: 4px; @include borderRadius(8px);}
		h2 {
			color: $cOrange; @include fh(16px, 22px); font-weight: bold; margin: 6px 0 4px;
			span {display: inline-block; position: relative; padding-left: 24px;}
			a {
				display: block; width: 20px; height: 20px; position: absolute; margin-top: -11px; left: 0; top: 50%;
				i.ico {left: 0; top: 0; @include opacity(0.6);}
				&:hover i.ico {@include opacity(1);}
			}
		}
		h3 {
			color: $cBase; @include fh(15px); font-weight: 500; margin: 1px 0;
			span {display: block;}
		}
		p.txt {margin: 14px 0 0;}
	}
}

// HP boxes
.hp-bottom-boxes {
	position: relative; margin: 30px -12px;
	> div {
		float: left; width: 50%; padding: 0 12px; position: relative;
		&:first-child {
			&:before{
				content: ""; width: 1px; height: 40px; position: absolute; right: 0; top: 8px; background: $cGreen;
			}
		}
	}
	h2 {
		@include fh(30px); font-weight: 300; margin: 8px 0;
		text-align: center; color: $cGreen;
		span {
			display: inline-block; position: relative; padding-left: 50px;
			i.ico {left: 0; top: 50%; margin-top: -16px;}
		}
	}
	.gallery-wrap, .video-wrap {margin: 30px 0;}
	.video-wrap {
		width: 100%; padding-bottom: 56.25%; position: relative; height: 0;
		iframe {position: absolute; left: 0; top: 0;}
	}
}

// Galerie
.gallery-wrap {
	margin: 20px 0;
	ul {
		margin: 0 -4px; position: relative;
		li {
			float: left; width: 33.3333333%; padding: 4px;
			a {
				@include borderRadius(6px);
				display: block; border: 1px solid #fff; padding: 3px; position: relative;
				img {
					@include borderRadius(4px); @include image();
					display: block;
				}
				&:before {
					background: rgba($cBlue, 0.6) url('../image/zoom.png') 50% 50% no-repeat; position: absolute; left: 0; top: 0; @include borderRadius(5px); content: ""; width: 100%; height: 100%;
					display: none;
				}
				&:hover {
					&:before {display: block;}
				}
			}
		}
	}
}

.popup-bg
{
	background-color: black;
	z-index:1001;
	-moz-opacity: 0.8;
	opacity:.50;
	filter: alpha(opacity=50);
	position: absolute;
	width: 100%;
	height: 100%;
	cursor: pointer;
	top: 0%;
	left: 0%;
}

.popup-content
{
	position: absolute;
	top: 25%;
	left: 25%;
	width: 50%;
	height: 50%;
	position: absolute;
	z-index:1002;
	overflow: auto;
	cursor: pointer;
}

.popup-content img {
	margin: 0 auto;
}