._text {
	color: $cBase; @include _font();
	p {
		margin: 12px 0 18px; text-align: justify;
		&.indent-left {margin-left: 16px;}
		&.indent-left-2 {margin-left: 32px;}
	}
	a {@include textDec(1,0);}
	hr {clear: both; margin: 10px 0; background: $cLine; height: 1px; @include fh(0em); overflow: hidden; text-indent: -9999px; border: none; padding: 0;}
	// Title
	@include headings {font-weight: 700; color: $cBase; text-transform: uppercase;}
	h2 { @include fh(18px, 1.2em); margin: 26px 0 8px; }
	h3 { @include fh(16px, 1.2em); margin: 22px 0 8px; }
	h4 { @include fh(15px, 1.2em); margin: 18px 0 6px; }
	h5 { @include fh(14px, 1.2em); margin: 16px 0 6px; }
	h6 { @include fh(14px, 1.2em); margin: 14px 0 4px; }
	// List
	ul {
		margin: 8px 16px;
		li {
			position: relative; padding: 2px 0; padding-left: 12px;
			&:before {position: absolute; content: "-"; left: 0; top: 9px; line-height: 2px; color: $cBase;}
		}
	}
	// Table
	table {
		width: 100%; border-collapse: separate; @include fh(14px);
		td, th {text-align: left; padding: 10px 12px 8px; margin: 1px; vertical-align: top;}
		th {background: $cBlue; color: #fff; font-weight: normal;}
		thead th {font-weight: 700;}
		td {background: $cLineLight;}
	}
	// Image
	img {@include image(); margin: 8px 16px; padding: 2px; border: 1px solid $cLineLight;}
	p.img-left {
		float: left; margin-top: 0;
		img {margin: 0 16px 4px 0;}
	}
	// Galerie
	.text-gallery {
		position: relative;
		margin: 14px -12px;
		> div {
			float: left; width: 16.66666%; padding: 8px 10px;
			a {
				display: block; @include borderRadius(50%); border: 5px solid $cLineLight;
				img {@include image(); display: block; @include borderRadius(50%); padding: 0; margin: 0; border: none;}
				&:hover {border-color: $cOrange;}
			}
		}
	}
}


/**
 * Tabulka
 */
table.tbl {
	width: 100%; border-collapse: separate; @include fh(14px);
	td, th {text-align: left; padding: 10px 12px 8px; margin: 1px; vertical-align: top;}
	th {background: $cBlue; color: #fff; font-weight: normal;}
	thead th {font-weight: 700;}
	td {background: $cLineLight;}
	// text
	a {@include textDec(1,0);}
	ul, p {margin: 8px 0;}
	td.txt {padding-top: 2px; padding-bottom: 2px;}
	td.amount {font-weight: 500;}
	td.price {@include fh(15px); font-weight: 500;}
}