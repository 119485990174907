@media (max-width: 780px) {	
	._mode-full, ._mode-mobile {display: none;}
	._mode-tablet {display: block; text-align: left;}

	/**
	 * Homepage
	 */
	.hp-slide {
		ul.reasons li {
			padding-right: 20px; padding-left: 60px;
			i.ico {left: 14px;}
		}
	}
	.review-outer {
		padding-top: 10px; padding-bottom: 4px;
		.review-inner {
			> .review {
				float: none; width: auto; padding: 10px 20px; position: relative; text-align: left;
				&:before {background: url('../image/main-menu-separ.png') 50% 0 no-repeat; top: auto; @include rotate(180deg); height: 101px; width: 100%; position: absolute; bottom: 0; left: 0; content: ""; display: block; position: absolute;}
				&.review-last:before {display: none;}
			}
			.right, p.thumb {position: relative; z-index: 10;}
			p.thumb {
				float: left; width: 19%;
				img {@include image();}
			}
			.right {float: right; width: 79%;}
			h3 span {display: inline;}
			p.txt {margin-top: 8px;}
		}
	}
	// Skeleton
	&.homepage .main-outer {
		padding: 0;
	}
	.main-inner {
		@include borderRadius(0);
		background: none;
		padding-left: 18px;
		padding-right: 18px;
	}
	// Filter
	.frm-filter {
		label.lbl {
			width: auto;
			float: none;
		}
		.frm-float-left {
			float: none;
		}
	}

	.banner-image.desktop {
		display: none;
	}
}