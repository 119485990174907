.contact-inner h2.main-title {
	margin-top: 0;
}

.contact-list {
	margin: 26px 0;
	> div {padding: 0; margin: 20px 0;}
	p.thumb {
		@include borderRadius(130px);
		float: left; width: 33%; max-width: 140px; background: #fff; border: 5px solid $cLineLight; text-align: center;
		img {width: 100%; height: auto; max-width: 130px; display: block; @include borderRadius(130px);}
	}
	p.thumb-unstyled {
		float: left; width: 33%; max-width: 140px; text-align: center;
		img {width: 100%; height: auto; max-width: 130px; display: block;}
	}
	.txt {
		float: right; width: 63%; padding: 10px 0;
	}
	h2 {@include fh(18px); color: $cBase; font-weight: 700;}
	p.contact-list-fnc {
		color: $cBlue; margin: 2px 0 10px;
		strong {font-weight: 400;}
	}
	ul.list {
		strong {font-weight: 500;}
		a {color: $cBase; @include textDec(0,1);}
	}
}

ul.contact-data {
	margin: 20px 0;
	li {
		padding: 1px 0;
	}
}

.contact-info {
	margin: 20px 0;
	padding-bottom: 20px;
	border-bottom: 1px solid $cLineLight;
	> div {
		padding-left: 55px;
		position: relative;
		min-height: 40px;
		display: table;
		width: 100%;
		strong {
			display: block;
		}
		.fa {
			@include fh(28px, 40px);
			text-align: center;
			display: block;
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -20px;
			height: 40px;
			width: 50px;
			color: $cBlue;

		}
		> div {
			display: table-cell;
			vertical-align: middle;
		}
	}
	a {
		@include textDec(1,0);
		font-weight: normal;
	}
}