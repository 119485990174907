.large-title {
	background-color: $cOrange;
	color: $cWhite;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 20px 26px 15px;
	font-weight: 400;
	span {
		display: block;
	}
	.title-text {
		@include fh(24px, 1.1em);
		font-weight: 500;
		text-transform: uppercase;
	}
	.title-step {
		@include fh(16px, 1.2em);
		padding-left: 12px;
		white-space: nowrap;
	}

	+ .section-bg {
		border-top-width: 0 !important;
	}
	@media (max-width: $screen-sm-max) {
		padding-left: $gridIndent;
		padding-right: $gridIndent;
	}
	@media (max-width: $screen-xs-max) {
		.title-text {
			@include fh(20px, 1.1em);
		}
		.title-step {
			@include fh(14px, 1.2em);
			padding-left: 8px;
		}
	}
}

/**
 * Clearfix
 */
.clearfix {
	&:before, &:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

.order-place-msg {
	margin: 16px 0;
	@include fh(16px);
	border-left: 3px solid $cOrange;
	background-color: rgba($cOrange, 0.08);
	padding: 12px;
	p + p {
		margin-top: 2px;
	}
	a {
		color: $cOrange;
		@include textDec(1, 0);
		font-weight: bold;
		&:hover {
			color: $cOrange;
		}
	}
}

/**
 * Small wrap
 */
.wrap-sm {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	max-width: 800px;
}

/**
 * Inner
 */
.main-inner-beta {
	background: #fff;
	border-radius: 6px;
	&.section, .section {
		padding: 24px 26px;
		&.section-bg {
			position: relative;
			overflow-x: hidden;
			border-top: 1px solid $cLine;
			> * {
				z-index: 10;
				position: relative;
			}
			&:before {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				z-index: 1;
				background: url("../image/bg.png") 50% 0 no-repeat;
				display: block;
				content: "";
				height: 100%;
				opacity: 0.3;
			}
		}
	}
}

.main-inner-white {
	background-color: #fff !important;
}

/**
 * Btn group
 */
.btn-group {
	p {
		display: inline-block;
		min-width: 120px;
		padding: 4px;
		margin: 0;
	}
}

@media (max-width: $screen-sm-max) {
	.btn-group-md {
		display: none;
	}
}

/**
 * Indent
 */
.padding-horizontal {
	padding-left: 40px;
	padding-right: 40px;
}

.padding-vertical {
	padding-top: 40px;
	padding-bottom: 40px;
}

.margin-vertical {
	margin-top: 40px;
	margin-bottom: 40px;
}

.margin-vertical-md {
	margin-top: 26px;
	margin-bottom: 26px;
}

/**
 * Pull
 */
.pull-left {
	float: left !important;
}

.pull-right {
	float: right !important;
}

.pull-reset {
	float: none !important;
}

/**
 * Responsive image
 */
.img-responsive {
	display: inline-block;
	height: auto;
	max-width: 100%;
}

.img-responsive-block {
	display: block;
	height: auto;
	max-width: 100%;
}

/**
 * Aligment
 */
.text-center {
	text-align: center;
}

.text-justify {
	text-align: justify;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

/**
 * Check list
 */
ul.default-check-list {
	@include fh(18px);
	margin: 28px 2px;
	li {
		padding: 6px 0;
		&:before {
			font-family: "FontAwesome";
			@extend .fa-check-circle;
			color: $cGreen;
			display: inline-block;
			margin-right: 6px;
		}
	}
}

/**
 * Title
 */
.main-title-beta {
	color: $cOrange;
	@include fh(30px);
	font-weight: $semibold;
	margin-bottom: 20px;
	text-align: center;
	&.limited {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		max-width: 600px;
	}
	&.dark {
		color: $cBase;
	}
	&.left {
		text-align: left;
	}
	&.sm {
		@include fh(24px);
	}
}

@media (max-width: $screen-md-max) {
	.main-title-beta br {
		display: none;
	}
}

@media (max-width: $screen-sm-max) {
	.main-title-beta.main-title-sm-alone {
		padding-bottom: 16px;
	}
}

// Subtitle
.main-subtitle {
	color: $cOrange;
	@include fh(24px);
	font-weight: $semibold;
	margin: 0 0 16px;
	span {
		font-size: 18px;
		color: $cBlue;
	}
	strong {
		background: $cOrange;
		color: #fff;
		text-transform: uppercase;
		font-weight: $normal;
		display: inline-block;
		padding: 2px 6px 0;
		font-size: 18px;
	}
	+ p {
		padding-top: 0 !important;
		position: relative;
		margin-top: -8px;
	}
}

/**
 * Video wrap
 */
.video-wrap {
	max-width: 760px;
	position: relative;
	margin: 30px auto;
	.video {
		padding-bottom: 56.25%;
		position: relative;
		height: 0;
		width: 100%;
	}
	iframe {
		position: absolute;
		left: 0;
		top: 0;
	}
}

/**
 * Table
 */
.tbl-beta {
	margin: 16px 0;
	width: 100%;
	border-collapse: separate;
	@include fh(14px);
	// Cells
	td, th {
		text-align: center;
		margin: 1px;
		vertical-align: middle;
	}
	// Header cell
	th {
		font-weight: $bold;
		background: $cBlue;
		padding: 10px 12px 8px;
		color: #fff;
	}
	// Data cell
	td {
		background: $cLineLight;
		padding: 6px 12px 6px;
	}
	// Content
	ul, p {
		margin: 8px 0 !important;
		padding: 0 !important;
	}
	ul li {
		padding: 1px 0;
	}
	// Links
	a {
		color: $cGreen;
		@include textDec(1, 0);
		font-weight: $bold;
	}
	.links {
		.edit {
			color: $cGreen;
		}
		.remove {
			color: $cOrange;
			a {
				color: $cOrange;
			}
		}
	}
	// Text
	td.td-text {
		text-align: left;
	}

	// text
	/*a {@include textDec(1,0);}
	td.txt {padding-top: 2px; padding-bottom: 2px;}
	td.amount {font-weight: 500;}
	td.price {@include fh(15px); font-weight: 500;}*/
}

/**
 * Colors
 */
.color-green {
	color: $cGreen;
}

.color-orange {
	color: $cOrange;
}

/**
 * Table minimal
 */
.tbl-beta-minimal {
	width: 100%;
	margin: 16px 0;
	border-collapse: collapse;
	thead {
		th {
			font-weight: $bold;
			background: $cLineLight;
			border-top: 1px solid $cLine;
			border-bottom: 1px solid $cLine;
			padding: 10px 6px 8px;
		}
	}
	tbody {
		td {
			padding: 12px 6px 10px;
			background: #fff;
			border-bottom: 1px solid $cLine;
		}
	}
	// Checkbox
	.icheckbox, .iradio {
		display: inline-block;
	}
	// Light version
	&.light {
		tbody td {
			border-bottom-color: $cLineLight;
			background: none;
		}
	}

	// Option
	td.td-option {
		> div {
			position: relative;
			display: inline-block;
			padding-left: 22px;
			.iradio, input[type="radio"] {
				display: block;
				position: absolute;
				left: 0;
				top: 50%;
				margin-top: -1px;
				transform: translateY(-50%);
			}
		}
	}

	// Responsive wrap
	&[data-responsive="1"] {
		@media (max-width: $screen-md-max) {
			border-top: 3px solid $cLine;
			&, tbody, th, td, tr {
				display: block;
			}
			thead {
				display: none;
			}
			tr {
				border-bottom: 3px solid $cLine;
			}
			tbody td {
				background-color: transparent;
				border: none;
				position: relative;
				&[data-title] {
					background-color: lighten($cLineLight, 2%);
				}
				&[data-label] {
					display: flex;
					padding: 8px 12px;
					border-top: 1px solid $cLineLight;
					&:before {
						//width: 30%;
						min-width: 30%;
						margin-right: $gridIndent;
						content: attr(data-label);
						font-weight: $bold;
						position: relative;
						color: $cBlueDark;
						box-sizing: border-box;
						//background-color: $cLineLight;
					}
				}
			}
		}
	}
}

/**
 * Tooltip
 */
.tooltip {
	color: $cBlue;
	display: inline-block;
	font-size: 1.15em;
	cursor: pointer;
	&:hover {
		color: $cGreen;
	}
}

/**
 * Table  - prehled produktu
 */
$mostcomf: rgba($cOrange, 0.08);
table.products-overview {
	width: 100%;
	border-collapse: collapse;
	text-align: center;
	td, th {
		padding: 12px 10px;
	}
	// Head
	thead {
		tr.throw th {
			padding: 6px 10px 6px;
			@include fh(18px, 1.3em);
			color: #555;
			span {
				color: $cGreen;
			}
			border-bottom: 2px solid $cLine;
			&:last-child {
				background: rgba($cOrange, 0.08);
			}
		}
		th.mostcomf {
			padding: 20px 0 6px;
			color: $cOrange;
			@include fh(24px, 1.25em);
			background: $mostcomf;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1e5799+0,7db9e8+100&0+0,1+100 */
			background: -moz-linear-gradient(top, rgba($cOrange, 0) 0%, $mostcomf 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba($cOrange, 0) 0%, $mostcomf 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba($cOrange, 0) 0%, $mostcomf 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		}
	}
	// Body
	tbody {
		@include fh(15px);
		th {
			width: 20%;
			text-align: left;
		}
		th, td {
			border-bottom: 1px solid rgba($cLine, 0.1);
		}
		td:last-child {
			background: $mostcomf;
		}
		// Ico
		i.fa-check {
			color: $cGreen;
		}
		i.fa-close {
			color: $cRed;
		}
	}
	// Foot
	tfoot {
		@include fh(15px);
		td:last-child {
			background: $mostcomf;
		}
	}
}

.select-branch-placeholder {
	h2 {
		margin-top: 36px;
		margin-bottom: 14px;
	}
}

/**
 * ORDER STEPS TO RESPONSIVE
 */
.wrap-responsive {
	@media (max-width: $screen-lg-max) {
		padding: 0;
		.main-responsive-inner {
			border-radius: 0;
		}
	}
}

.responsive-content {
	@media (max-width: 1500px) {
		padding-bottom: 100px;
		#basket {
			position: fixed !important;
			left: 0;
			right: 0 !important;
			bottom: 0;
			top: auto;
			border-radius: 0;
			width: auto;
			text-align: left;
			padding: 12px 14px;
			box-shadow: 0 0 20px rgba(#000, 0.2);
			p {
				padding: 2px;
				strong {
					display: inline;
				}
			}
		}
	}
}

.gallery-beta[data-type="proofreading"] {
	margin-top: 0;
	text-align: center;
	li {
		display: inline-block;
		width: 160px;
		float: none;
	}
}

.main-responsive-inner {
	.gallery-beta[data-type="preview"] {
		display: none;
		@media (min-width: $screen-lg-min) {
			display: block;
		}
	}
	.main-title, h1.main-title {
		font-weight: 500;
	}

	@media (max-width: $screen-lg-max) {
		#breadcrumbs {
			margin-top: 14px;
			margin-bottom: 14px;
			margin-left: -26px;
			margin-right: -26px;
			border-radius: 0;
			padding-bottom: 4px;
			li {
				margin-bottom: 5px;
			}
		}
		.order-place-msg {
			position: relative;
			margin-left: -12px;
			margin-right: -12px;
			margin-bottom: 0;
		}
	}

	@media (max-width: $screen-xs-max) {
		#breadcrumbs {
			li {
				display: block;
				float: none;
				&:before {
					display: none;
				}
			}
		}
	}

	// Order table
	.summary-actions {
		p {
			display: inline-block;
			+ p {
				margin-left: 10px;
			}
		}
	}
	.tbl-order-wrap, .tbl-beta {
		.summary-actions {
			@media (min-width: $screen-xlg-min) {
				float: right;
				margin-left: 10px;
			}
		}
	}

	@media (min-width: $screen-md-min) {
		.order-summary {
			display: none;
		}
	}
	@media (max-width: $screen-sm-max) {
		.order-summary-table {
			display: none;
		}
	}

	// Price
	.price-subtotal {
		.without-tax {
			@media (max-width: $screen-xs-max) {
				@include fh(18px);
			}
		}
	}

	.order-place-msg[data-type="sm"] {
		padding: $gridIndent $gridIndent;
		@include fh(15px);
		color: $cOrange;
		font-weight: $bold;
	}

	// Discount
	#frm-addDiscount {
		@media (max-width: $screen-md-max) {
			.order_total-coupon {
				float: none !important;
				width: auto;
			}
		}
		@media (max-width: $screen-sm-max) {
			.order_total-coupon {
				margin-top: $gridIndent;
				margin-bottom: $gridIndent;
			}
		}
		@media (max-width: $screen-xs-max) {
			.order_total-coupon {
				.coupon-wrap {
					text-align: center;
					.inp {
						display: inline-block;
						float: none !important;
						margin-bottom: ($gridIndent / 2);
					}
					.btn {
						display: inline-block;
						float: none !important;
					}
				}
			}
		}
	}

	// Sectionpadding
	.section[data-responsive] {
		@media (max-width: $screen-sm-max) {
			padding-left: $gridIndent;
			padding-right: $gridIndent;
		}
	}
}

.order-responsive-inner {
	background: #fff url('../image/main-bg.png') 50% 100% no-repeat !important;
	padding: 24px 26px;
	border-radius: 0;
}

.order-data-boxes {
	.box-item {
		background-color: $cLineLight;
		padding: $gridIndent $gridIndent;
	}

	h2 {
		@include fh(18px, 1.1em);
		margin: 0 0 ($gridIndent / 2);
	}

	ul {
		li + li {
			margin-top: 4px;
		}
		a {
			@include textDec(1, 0);
		}
	}

	.more {
		color: $cOrange;
		text-align: center;
		padding-top: $gridIndent;
	}

	// Responsive
	@media (min-width: $screen-lg-min) {
		display: flex;
		position: relative;
		&:before {
			width: 2px;
			left: 50%;
			margin-left: -1px;
			background-color: #fff;
			top: 0;
			bottom: 0;
			position: absolute;
			content: "";
		}
		.box-item {
			width: 50%;
		}

	}
	@media (max-width: $screen-md-max) {
		.box-item + .box-item {
			margin-top: 2px;
		}
	}
}

.order-summary-sub {
	+ .order-summary-sub {
		padding-top: $gridIndent / 2;
	}

	@media (min-width: $screen-sm-min) {
		.col-sm-6 {
			float: right;
			width: 50%;
			padding-left: $gridIndent;
		}
	}
	@media (min-width: $screen-xs-min) {
		padding-left: 70px;
		position: relative;
	}
}

.order-summary {
	counter-reset: order-summary-counter;
	padding-bottom: $gridIndent;
	border-bottom: 2px solid $cLineLight;
	.order-summary-item {
		border-top: 2px solid $cLineLight;
		padding: $gridIndent 0 0;
		margin-top: $gridIndent;
		&:before {
			counter-increment: order-summary-counter;
			content: "#" counter(order-summary-counter);
			display: block;
			color: $cLine;
			font-weight: 500;
			@include fh(24px, 1em);
			margin-bottom: 12px;
		}
		.row {
			&:before, &:after {
				display: table;
				content: " ";
				clear: both;
			}
			ul {
				margin-bottom: $gridIndent;
				li + li {
					margin-top: 4px;
				}
				.li-extra {
					margin-top: 10px;
					a {
						color: $cBase;
						@include textDec(1, 0);
						position: relative;
						padding-left: 20px;
						i {
							position: absolute;
							left: 2px;
							top: 4px;
							display: block;
							@include fh(13px, 1em);
						}
					}
				}
			}
			+ .summary-actions {
				margin-top: 0;
			}
		}
		.col-sm-6 {
			padding: 0 $gridIndent $gridIndent;
		}
		.summary-actions {
			margin-top: $gridIndent;
		}

		@media (min-width: $screen-sm-min) {
			.col-sm-6 {
				float: left;
				width: 50%;
			}
		}
		@media (min-width: $screen-xs-min) {
			padding-left: 70px;
			position: relative;
			&:before {
				margin: 0;
				position: absolute;
				left: 0;
				top: 16px;
			}
		}
	}
}

/**
 * Order tabs
 */
.tabs-container {
	.tabs-navi {
		margin-top: 20px;
	}

	// Responsive
	@media (min-width: $screen-lg-min) {
		.tabs-navi {
			display: none;
		}
		.responsive-show-preview {
			display: none;
		}
	}
	@media (max-width: $screen-md-max) {
		.tabs-pane {
			background-color: rgba($cBlueDark, 0.04);
			border-bottom: 3px solid $cBlueDark;
			display: none;
			float: none !important;
			width: auto;
			padding: 8px $gridIndent 20px;
			&.active {
				display: block;
			}
		}
		&[data-inResponsiveSection] {
			.tabs-pane, .tabs-navi {
				position: relative;
				margin-left: -26px;
				margin-right: -26px;
			}
		}
		.tabs-pane[data-type="preview"] {
			.order_plate {
				max-width: 500px;
				margin-left: auto;
				margin-right: auto;
				margin-top: 20px;
			}
		}
		.tabs-pane[data-type="setting"] {
		}
	}
	@media (max-width: $screen-sm-max) {
		&[data-inResponsiveSection] {
			.tabs-pane, .tabs-navi {
				margin-left: -$gridIndent;
				margin-right: -$gridIndent;
			}
		}
	}
}

.tabs-navi {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	border-bottom: 3px solid $cBlueDark;
	box-shadow: 0 10px 10px -10px rgba($cBlueDark, 0.5);
	.item {
		display: block;
		position: relative;
		padding: 14px 14px 10px;
		text-align: center;
		cursor: pointer;
		font-weight: 500;
		background-color: darken($cLineLight, 4%);
		color: $cBlueDark;
		transition: all 0.25s ease;
		margin: 0 4px;
		&:hover {
			color: $cWhite;
			background-color: lighten($cBlueDark, 16%);
		}
		&.active {
			color: $cWhite;
			font-weight: bold;
			background-color: $cBlueDark;
		}
	}
}

.landing-section {
	margin-top: $gridIndent;
	h3 {
		margin-bottom: $gridIndent;
	}
	+ .landing-section {
		margin-top: (2*$gridIndent);
	}
}

.landing-items {
	.item {
		text-align: center;
		float: left;
		width: 120px;
		height: 190px;
		padding: 5px;
	}
}

.annotation {
	.annotation-content {
		// Umistneni dole
		&.annotation-content-bottom {
			@include borderRadius(8px);
			left: 50%;
			top: 40px;
			right: auto;
			max-width: 280px;
			transform: translateX(-50%);
			&:before {
				left: 50%;
				right: auto;
				margin-left: -11px;
				top: -25px;
				@include rotate(90deg);
			}
			// Obrazek
			&.annotation-content-image {
				p.annotation-thumb {
					width: 25%;
					box-sizing: border-box;
				}
				p.annotation-txt {
					width: 75%;
					box-sizing: border-box;
				}
			}
		}
	}
}

.mintagePreviewTable {
	.hss-preview {
		width: 100%;
		position: relative;
		height: 0;
		padding-bottom: 130%;
		.hss-preview-box {
			position: absolute;
			background: $cWhite url('/image/beta/cb-hss-plate-sticker.jpg') 0 0 no-repeat;
			padding-top: 81px;
			width: 100%;
			max-width: 300px;
			right: 30px;
			top: 30px;
			text-align: left;
			.work-type {
				background-color: #2f2c2d;
				padding: 12px 10px;
				color: #fff;
			}
			.work-content {
				padding: 8px 14px;
				p {
					padding: 4px 0 6px;
				}
				.year {
					text-align: right;
					padding-top: 10px;
					padding-bottom: 4px;
				}
			}
		}
	}
}

.bottom-beta {
	p, h2 {
		margin: 22px 0;
	}
	.btn-group {
		p {
			margin-top: 8px;
			margin-bottom: 8px;
		}
	}
	@media (max-width: 600px) {
		.btn-group p {
			padding-top: 0;
			padding-bottom: 0;
			float: none !important;
			width: auto !important;
		}
	}
}

.partners-list {
	$p: 12px;
	margin-top: 40px;
	margin-bottom: 40px;
	+ .partners-list {
		margin-top: 60px;
	}
	._msg {
		margin-top: 30px;
	}
	.items {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		margin-left: -($p);
		margin-right: -($p);
		&.sm {
			.item {
				width: (100% / 3);
				.image {
					height: 160px;
				}
				.content {
					.name {
						@include fh(18px);
					}
				}
			}
		}
		&.lg {
			.item {
				width: (100% / 2);
				.image {
					height: 280px;
				}
			}
		}

		.item {
			position: relative;
			padding: ($p + 6);
			&:before {
				position: absolute;
				left: $p;
				right: $p;
				top: $p;
				bottom: $p;
				display: block;
				border: 1px solid lighten($cLine, 8%);
				border-radius: 4px;
				content: "";
				pointer-events: none;
			}
			.image {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 20px;
				img {
					display: block;
					max-width: 100%;
					max-height: 100%;
					height: auto;
					width: auto;
				}
			}
			.content {
				text-align: center;
				padding: 0 $p 20px;
				.name {
					color: $cOrange;
					@include fh(20px);
					font-weight: bold;
					margin-bottom: 14px;
				}
				.city {
					margin-bottom: 4px;
					font-weight: bold;
				}
				.text {
					line-height: 1.5em;
				}
				.link {
					margin-top: 14px;
					a {
						color: $cBlueDark;
						@include textDec(1, 0);
					}
				}
			}
		}
	}

	@media (max-width: 960px) {
		._msg {
			margin-left: 0;
			margin-right: 0;
		}
		.items.lg .item .image {
			height: 220px;
		}
	}
	@media (max-width: 800px) {
		$p: 6px;
		.items {
			margin-left: -($p);
			margin-right: -($p);
			.item {
				position: relative;
				padding: ($p + 4);
				&:before {
					left: $p;
					right: $p;
					top: $p;
					bottom: $p;
				}
				.content {
					text-align: center;
					padding: 0 $p 18px;
				}
			}
		}
		.items.sm .item {
			width: 50%;
		}
	}

	@media (max-width: 540px) {
		$p: 0px;
		.items {
			margin-left: auto;
			margin-right: auto;
			max-width: 400px;
			.item {
				position: relative;
				padding-left: ($p + 4);
				padding-right: ($p + 4);
				&:before {
					left: $p;
					right: $p;
				}
			}
		}
		.items.sm .item, .items.lg .item {
			width: 100%;
			.image {
				height: auto;
				img {
					max-width: 180px;
				}
			}
		}
	}

	@media (max-width: 400px) {
		$p: 0px;
		.items {
			margin-left: auto;
			margin-right: auto;
			max-width: 400px;
			.item {
				position: relative;
				padding-left: ($p + 4);
				padding-right: ($p + 4);
				&:before {
					left: $p;
					right: $p;
				}
			}
		}
	}
}

// **
// Sell with us: theme: DEFAULT
// **
.sell-with-us--default {
	margin-top: 80px;
	@media (max-width: $screen-xs-max) {
		._msg {
			margin-left: 0;
			margin-right: 0;
		}
	}
}

// **
// Sell with us: HEADER
// **
.sell-with-us_header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.text {
		padding-right: 60px;
		max-width: 600px;
		.main-title-beta {
			text-align: left;
		}
	}
	@media (max-width: $screen-md-max) {
		flex-direction: column;
		.image {
			margin-top: 30px;
		}
		.text {
			padding-right: 0;
		}
		.text .main-title-beta {
			text-align: center;
			padding-top: 20px;
		}
	}
}

// **
// Sell with us: AUTHOR
// **
.sell-with-us_author {
	background-color: $cBase;
	color: $cWhite;
	padding: 50px 30px;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow: 0 10px 50px rgba(0,0,0,0.25);
	.text {
		max-width: 600px;
		@include fh(18px, 1.5em);
		font-style: italic;
	}
	.author {
		margin-top: 30px;
		@include fh(16px, 1.5em);
		strong {
			font-weight: $semibold;
		}
	}
	@media (max-width: $screen-sm-max) {
		padding: 30px;
		.text {
			@include fh(16px, 1.5em);
		}
	}
}

// **
// Sell with us: PRICE LIST
// **
.sell-with-us_price-list {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	max-width: 700px;
	table {
		margin-top: 10px;
		border-collapse: collapse;
		width: 100%;
		tr {
			border-bottom: 2px solid $cLineLight;
		}
		td, th {
			padding: 14px 12px 12px;
			vertical-align: top;
		}
		td {
			color: $cOrange;
			font-weight: $bold;
			text-align: right;
		}
		th {
			font-weight: normal;
			h3 {
				@include fh(16px);
			}
			p {
				margin-top: 6px;
				max-width: 430px;
			}
		}
	}
}

// **
// Sell with us: BENEFITS
// **
.sell-with-us_benefits {
	$p: 16px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	max-width: 800px;
	.items {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		margin-left: -($p);
		margin-right: -($p);
	}
	.item {
		position: relative;
		padding: (2*$p) (2*$p) (2*$p + 14);
		text-align: center;
		width: 50%;
		&:before {
			position: absolute;
			left: $p;
			right: $p;
			top: $p;
			bottom: $p;
			display: block;
			border: 1px solid lighten($cLine, 8%);
			border-radius: 4px;
			content: "";
			pointer-events: none;
		}
		.name {
			color: $cOrange;
			@include fh(20px);
			font-weight: bold;
			margin-bottom: 14px;
		}
		i {
			margin-top: 30px;
			margin-bottom: 30px;
			font-weight: bold;
			@include fh(48px, 1em);
			color: $cBlueDark;
		}
		.text {
			line-height: 1.5em;
		}
	}
	@media (max-width: $screen-sm-max) {
		max-width: 400px;
		.item {
			width: 100%;
		}
	}
}

// **
// Sell with us: STATS
// **
.sell-with-us_stats {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	max-width: 800px;
	._msg {
		margin-bottom: 30px;
		margin-top: 20px;
	}
}

// **
// Sell with us: FAQ
// **
.sell-with-us_faq {
	margin-left: auto;
	margin-right: auto;
	position: relative;
	max-width: 700px;
	.items {
		margin-top: 10px;
	}
	.item {
		margin-bottom: 20px;
		.question {
			@include fh(16px);
		}
		.answer {
			border-left: 3px $cLineLight solid;
			padding: 6px 12px;
			margin-top: 8px;
		}
	}
}

// **
// Sell with us: BOTTOM
// **
.sell-with-us_bottom {
	._msg {
		max-width: 460px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
	}
	.contact-list {
		max-width: 420px;
		margin-left: auto;
		margin-right: auto;
		position: relative;
	}
}

// **
// Gallery
// **
.gallery-image, .gallery-image-simple {
	display: block;
	position: relative;
	img {
		display: block;
	}
	&:before {
		position: absolute;
		content: "";
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: rgba(0,0,0,0.3);
		display: none;
	}
	&:after {
		background: rgba($cWhite, 0.9);
		position: absolute;
		left: 50%;
		top: 50%;
		margin: -30px 0 0 -30px;
		@include borderRadius(50%);
		font-family: "FontAwesome";
		content: "\f002";
		@include fh(24px, 58px);
		text-align: center;
		width: 60px;
		height: 60px;
		color: $cOrange;
		display: none;
	}
	&:hover {
		&:before, &:after {
			display: block;
		}
	}
}
.gallery-image {
	img {
		@include borderRadius();
		border: 1px solid $cLine;
		padding: 2px;
	}
	&:before {
		@include borderRadius();
		left: 3px;
		right: 3px;
		top: 3px;
		bottom: 3px;
	}
}

table.table-responsive {
	@media (max-width: $screen-md-max) {
		min-width: 300px;
		border-bottom: 3px solid $cLine;
		thead th {
			display: none;
		}
		tbody {
			td, th {
				display: flex;
				align-items: center;
				border-bottom-width: 0;
				position: relative;
				text-align: left;
				&:first-child {
					border-top-color: $cLine;
					border-top-width: 3px;
				}
				&:last-child {
					border-bottom-width: 0;
				}
				img {
					max-width: 200px;
				}
				> div {
					padding: 10px 10px;
					width: 70%;
					&.actions {
						width: 100%;
						display: flex;
						padding: 0;
						flex-wrap: wrap;
						justify-content: center;
						p:not(:last-child) {
							margin-right: 12px !important;
						}
					}
				}
				&[data-th] {
					padding: 0;
					&:before {
						width: 30%;
						padding: 10px 8px;
						content: attr(data-th)": ";
						align-self: normal;
						color: $cWhite;
						background-color: #8db2c5;
						font-weight: $bold;
						display: flex;
						flex-direction: column;
						justify-content: center;
						position: relative;
						z-index: 1;
						box-sizing: border-box;
					}
				}
			}
		}
	}
}