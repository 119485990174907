/**
 * Buttons
 */
@mixin button($bg, $identifier: ".btn")
{
	a#{$identifier}, input#{$identifier}, button#{$identifier}, label#{$identifier} {
		@include borderRadius(18px); @include textDec(0,0); @include _font();
		border: 1px solid $bg; 
		display: block; 
		height: 40px; 
		padding: 0 26px; 
		margin: 0; 
		font-weight: 500; 
		font-size: 14px; 
		cursor: pointer; 
		background: $bg; 
		color: #fff;
		&:hover {background: darken($bg, 6%);}
		&:active {background: lighten($bg, 2%); @include boxShadow(0 0 6px darken($bg, 10%) inset); outline: 0;}
		&.btn-large {height: 60px; font-size: 24px; @include borderRadius(30px); font-weight: 300; padding: 0 40px;}
		&.btn-middle {height: 48px; font-size: 18px; @include borderRadius(22px); font-weight: 400; padding: 0 24px;}
		&.btn-mini {height: 30px; font-size: 14px; @include borderRadius(4px); padding: 0 18px; font-weight: 400;}
	}
	a#{$identifier}, label#{$identifier} {
		line-height: 40px;
		&.btn-large {line-height: 60px;}
		&.btn-middle {line-height: 48px;}
		&.btn-mini {line-height: 30px; @include textDec(0,0);}
	}
}
// Ikona
input.btn-inline {display: inline-block !important;}
a, button, label {
	&.btn-inline {display: inline-block !important;}
	&.btn-ico, &.btn-ico-right {
		span {display: block;}
		position: relative;
	}
	&.btn-ico {
		span {padding-left: 28px;}
		i.ico {left: 38px; top: 50%; margin: -14px 0 0 0;}
		// FB button
		&.btn-fb i.ico {margin-top: -18px;}	
	}
	&.btn-ico-right {
		span {padding-right: 28px;}
		i.ico {right: 38px; top: 50%; margin: -14px 0 0 0;}
	}
}

// Zakladni
@include button(#65bb4c);
@include button(#e95241, ".btn-orange");
@include button($cBase, ".btn-base");
@include button($cLine, ".btn-default");
@include button(#375dae, ".btn-fb");


// Link
a.link {
	display: inline-block;
	color: $cLink;
	@include textDec(1,0);
	font-weight: 500;
}



/**
 * Formularove prvky
 */
// Vzor pro element
@mixin frm-element {
	@include _font(); @include borderRadius(3px);
	display: block; padding: 0; width: 100%; background: #fff; border: 1px solid $cLine; color: $cBase; font-size: 13px;
	-webkit-appearance: none; -moz-appearance: none; appearance: none;
	&:focus {@include boxShadow(0 0 4px $cLine);}
}

// Styl formulare
.frm {
	p {padding: 8px 0;}
	.frm-float-left {display: block; margin-right: 14px; float: left;}
	.frm-inp-wrap {display: block; float: left; width: 50%;}
	.frm-inp-wrap-sm {display: block; float: left; width: 20%;}
	.frm-tArea-wrap {display: block; float: left; width: 70%;}
	input.inp {@include frm-element; height: 38px; text-indent: 8px;}
	input.inp-mini {@include frm-element; height: 38px; width: 80px; float: left; text-align: center;}
	input.inp-inline {
		display: inline-block;
	}
	input.inp-center {
		text-indent: 0;
		text-align: center;
	}
	// TextArea
	textarea.tArea {@include frm-element; height: 120px; padding: 6px 8px;}
	// Select
	._select {display: block;}
	// Label
	label.lbl {
		display: block; font-weight: 400; color: $cBase; @include fh(14px); @include _font(); padding: 10px 6px 4px; float: left; width: 30%;
		&.required {
			font-weight: 700;
			span {color: $cOrange;}
		}
		&.bold {font-weight: 700 !important;}
		span.price {display: block; color: $cBlue; font-weight: normal;}
		a {@include textDec(1,0);}
	}
	p.required-info {color: $cOrange; font-weight: 700;}
	// label indent
	.indent-label {margin-left: 30%;}
	// iCheck
	.chck-wrap {
		.icheckbox, .iradio {float: left; margin: 5px 8px 5px 0;}
		label.lbl {
			margin-left: 24px; float: none; width: auto !important; padding-top: 4px; /*font-weight: 700;*/ cursor: pointer;
			padding-left: 0;
		}
	}
	.chck-wrap-step-4 {
		label.lbl {margin-left: 0; float: left;}

	}
	.radio-wrap {padding: 0 !important;}
	// annotation
	.annotation-wrap {
		padding: 6px 0;
		p {padding: 0 !important; float: left;}
		p.fixed-width {width: 90%;}
		&.chck-wrap {
			label.lbl {float: left; margin-left: 0;}
			.annotation {margin: 0;}
		}
		&.input-file-wrap {
			label.lbl {padding-right: 0;}
			p.file-outer {float: none;}
		}
	}
	// medium
	.el-md {
		label.lbl {
			padding-top: 6px;
		}
		.inp-mini {
			height: 32px; width: 60px;
		}
	}
}


// Formular s chybou
.frm-error {width: 60%; margin: 20px 0 0;}
.frm-order {
	margin: 20px 0 0;
	form {margin: 20px 0 0;}
	.indent-label {margin-left: 20%;}
	label.lbl {width: 20%;}
	.frm-inp-wrap {display: block; float: left; width: 30%;}
	.frm-tArea-wrap {display: block; float: left; width: 45%;}
	.frm-el-info {display: block; float: left; width: 35%; padding: 3px 8px 2px; @include fh(14px); color: darken($cBlue, 10%);}
	span.gopay {float: left; margin: 0 6px; padding: 4px 0;}
}


// Formular sleva
.frm-discount {
	h2.main-title {margin-bottom: 6px !important;}
	input.inp {width: 240px; float: left; height: 34px; margin: 3px 0; margin-right: 8px;}
}


// Form tabs
.frm-tabs {
	display: block;
	@include fh(0em);
	a {
		display: inline-block;
		@include fh(15px, 1.3em);
		@include textDec(0,0);
		@include transition(background .25s ease);
		padding: 8px 10px 7px;
		background: $cBlue;
		color: #fff;
		border-left: 1px solid darken($cBlue, 10%);
		// First
		&:first-child {
			@include borderRadius(3px 0 0 3px);
			border-left-width: 0;
		}
		// Last
		&:last-child {
			@include borderRadius(0 3px 3px 0);
		}
		// Only
		&:only-child {
			@include borderRadius(3px);
		}
		// Hover
		&:hover {
			background: $cOrange;
			cursor: pointer;
		}
		// Active
		&.active {
			background: $cGreen;
		}
	}
}

// Filter
.frm-filter {
	div.el, p {padding: 4px 0;}
	label.lbl {
		width: 140px;
	}
	p.chck-wrap {
		margin-left: 10px;
		padding: 4px 0 0;
	}
}


// Lightbox
.frm-lightbox {
	p, .el {
		padding-top: 10px;
		padding-bottom: 0;
	}
	.chck-wrap.frm-float-left {
		margin-right: 8px !important;
	}
}
