html, body, ul, p, ol 		{margin: 0; padding: 0;}
h1, h2, h3, h4, h5, h6 		{margin: 0; padding: 0; font-weight: bold;}
a, a:hover 					{text-decoration: none;}
img, a img 					{border: none;outline:none;}
li 							{list-style-type: none; margin: 0; padding: 0;}
.float-left 				{float:left !important;}
.float-right 				{float:right !important;}
.float-none 				{float:none !important;}
.align-left 				{text-align:left !important;}
.align-right 				{text-align:right !important;}
.align-center 				{text-align:center !important;}
.none 						{display: none;}
.no-txt 					{line-height: 1px !important; font-size: 0em !important; overflow: hidden !important; text-indent: -9999px !important;}
.clear {
	clear: both !important; height: 0px !important; line-height: 0px !important; font-size: 0px !important; overflow: hidden !important; display: block !important; margin: 0 !important; float: none !important; padding: 0 !important; background: none !important; border:0 !important; position: static !important; width: 0px !important; min-height: 0 !important;
	&:after, &:before {display: none;}
}
input:focus,textarea:focus,a:focus{outline:none;}
input::-moz-focus-inner,textarea::-moz-focus-inner{border:0;padding:0;}
body {
	@include _font(); @extend %minWidth; width: 100%; color: $cBase; background: #374047;
	font-size: 14px; line-height: 1.3em; text-align: center; overflow-x: hidden;
}
* {@include boxSizing();}
.wrap {@extend %minWidth; width: $_width; margin: 0 auto; text-align: left; position: relative;}
a {color: $cLink; @include textDec(0,1);}
strong,b,.bold {font-weight: 700;}
.semibold {font-weight: 500;}

._legal ol li {
	list-style-type: decimal;
	margin-left: 30px;

	ol li {
		list-style-type: lower-alpha;
	}
}

.banner {
	&-wrapper {
		text-align: center;
		margin: 32px 0 0;
		transition: top .25s linear;
		&-fixed {
			position: fixed;
			left: calc(((100vw - 980px) / 2) - 180px);
    		top: 155px;
		}
		&-scrolled {
			top: 60px;
		}
	}

	&-image {
		max-width: 100%;
		height: auto;
	}
}