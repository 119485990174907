.price-subtotal {
	@include fh(24px);
	text-align: right; margin: 20px 0;
	p {margin: 6px 0;} 
}
.order-step2-link-wrap {margin: 20px 0;}

// Bottom
.order-bottom {
	p {margin-left: 20px;}
	.link-wrap {
		padding-top: 22px;
		a {font-weight: 700; text-transform: uppercase; @include textDec(1,0); @include fh(14px, 20px);}
	}
	.btn-wrap {padding-top: 0; padding-bottom: 0;}
}

// Prehled objednavky
.order-data {
	position: relative; margin-left: -1px; margin-right: -1px;
	> div {
		float: left; width: 50%; padding: 0 1px;
		.inner {background: $cLineLight; padding: 2px 14px 2px; position: relative;}
	}
	h2 {@include fh(14px); font-weight: 700; margin: 10px 0;};
	ul {
		margin: 10px 0;
		li {padding: 1px 0;}
		&.delivery-data li {
			padding: 4px 0;
			&.map {
				span {display: inline-block; position: relative; padding-right: 6px;}
				a.btn-orange {
					position: absolute; left: 100%; top: 50%; margin-top: -16px; padding-left: 12px; padding-right: 12px; height: 32px; line-height: 32px;
					i.ico {margin-top: -8px; left: 14px;}
					span {padding-left: 22px;}
				}
			}
		}
	}
	p.more {
		position: absolute; right: 10px; top: 8px;
		a {font-weight: bold; @include textDec(1,0);}
	}
}

// Prehled objednavky
.order-done-delivery-info {
	padding: 20px 30px;
	> div {float: left; width: 50%; padding: 0 14px;}
	.inner {background: $cLineLight; border: 1px solid $cLine; @include borderRadius(4px); padding: 8px 24px;}
	h2 {@include fh(24px); font-weight: 400; text-align: center; margin: 12px 0;}
	p {text-align: center; margin: 16px 0;}
	p.important {color: $cOrange; font-weight: 500;}
	p.map-wrap {
		text-align: center;
		a.btn-orange {
			padding-left: 16px; padding-right: 18px; height: 32px; line-height: 32px;
			i.ico {margin-top: -8px; left: 18px;}
			span {padding-left: 20px;}
		}
	}
}

.order-plate-outer {
	h2 {
		@include fh(14px, 24px);
		position: relative; color: $cOrange; font-weight: 700; padding: 8px 0;
		padding-left: 50px;
		margin: 12px 0;
		span {
			@include borderRadius(30px); @include fh(24px, 42px);
			display: block; position: absolute; height: 40px; width: 40px; left: 0; top: 50%; margin-top: -20px; background: $cOrange; color: #fff; font-weight: 300; text-align: center;
		}
	}
	.plate-outer {
		float: left; width: 500px; margin-bottom: 30px;
		p.txt {margin: 12px 0 20px; font-weight: 500;}
	}
	.plate-right-outer {
		float: right; width: 396px;
		label.lbl {width: auto; font-weight: 500; padding-left: 0; padding-right: 8px;}
		.iradio {float: left;}
		p {padding: 6px 0;}
		.vertical-inner {margin-top: 12px; margin-bottom: 12px;}
	}
	.order-plate-indent {margin-left: 50px !important;}
}

// Vzhled desek
#plate {
	@include borderRadius(4px);
	background: $cLineLight 0 0 repeat;
	* {color: #666;}
	> div {height: 670px;}
	// Hrbet
	> .left {
		float: left; border-right: 1px solid $cLine; position: relative; width: 48px;
		p {
			@include rotate(-90deg); @include fh(16px, 40px);
			position: absolute; left: -150px; top: 160px; width: 335px; height: 40px; cursor: pointer;
		}
		p.left {top: 470px; text-align: left;}
		p.right {text-align: right;}
	}
	// Data desky
	> .right {
		float: left; width: 452px; padding: 14px 20px;
		p {padding: 6px 0;}
		input.inp {text-align: center; text-indent: 0;}
		textarea.tArea {text-align: center; padding-top: 10px;}
		.section-top {padding-bottom: 52px;}
		.section-other {
			padding-top: 110px; background: transparent 50% 0 no-repeat; background-size: 100px;
		}
		.section-bottom {
			padding-top: 30px;
			p {width: 40%;}
		}
	}
}

// Prilohy
.attachments-outer {
	position: relative; margin-left: -2px; margin-right: -2px;
	.attachment {
		float: left; width: 33.333333%; padding: 0;
		.inner {border: 1px solid $cLine; border-left-width: 0; background: #fff; padding: 12px 10px;}
		&:nth-child(3n+1) .inner {border-left-width: 1px;}
		p.thumb {
			float: left; margin: 0; width: 37%; padding: 0;
			a {display: block; @include borderRadius(50%); border: 5px solid $cLineLight;}
			img {@include borderRadius(50%); @include image(); display: block;}
		}
		.right {
			float: right; width: 60%; 
			h3 {@include fh(14px); text-transform: uppercase; font-weight: 700; color: $cOrange; margin: 6px 0 12px;}
			p {padding: 2px 0; margin: 0;}
			input.inp-mini {height: 30px; width: 26%;}
			label.lbl {width: 72%; font-weight: 500; @include fh(13px, 18px); padding: 5px 4px 2px 0;}
		}
	}
}
.amount-wrap {
	label.lbl {
		width: auto;
		font-weight: 500;
		padding-left: 0;
		padding-right: 8px;
	}
}


/**
 * Supply pooint
 */
.supply-point-outer {
	margin: 20px 0 0;
	@include borderRadius(6px);
	label.lbl {
		float: none; 
		width: auto; 
		padding: 0 1px 3px;
	}
	> .left {
		width: 42%; float: left; 
		.input-outer {
			> p:first-child {
				padding-top: 0;
				padding-bottom: 0;
			}
			input.inp {font-weight: 500; color: lighten($cBlueDark, 10%);}
		}
	}
	> .right {
		width: 58%; float: right;
		padding-left: 10px;
		padding-top: 21px;
		 //margin: 30px 0;
		//> p {text-align: right; padding: 0;}
		#map-outer {
			@include borderRadius(4px);
			border: 1px solid $cLine; background: #333; height: 500px;
		}
	}

}

.order-info-msg-icon-top
{
	margin-top: 20px;
	.messageIcon {
		font-size: 24px; 
		color: #8db2c5; 
		display: block; 
		float: left; 
		margin-right: 10px; 
		padding-top: 8px;
	}
}

// Point list
#supply-point-list {
	$hover: lighten($cOrange, 37.5%);
	@include borderRadius(4px);
	height: 452px; overflow-y: scroll; border: 1px solid $cLine; margin: 10px 0 0; padding: 0; background: #fff;
	> div {
		position: relative; padding: 8px 6px 8px 32px; border-bottom: 1px solid #eee;
		&:nth-child(2n) {background: #f7f7f7;}
		&:first-child {@include borderRadius(4px 4px 0 0);}
		&:last-child {@include borderRadius(0 0 4px 4px);}
		&:hover {cursor: pointer; background: $hover;}
		i.ico {left: 8px; top: 50%; margin-top: -9px;}
		span.distance {font-weight: 500;}
		span.delivery-date {
			color: $cGreen; font-weight: 500;
			&.textday {color: $cOrange; @include fh(1.1em);}
		}
		> p.data {
			display: table; table-layout: fixed; width: 100%;
			> span {display: table-cell; vertical-align: middle; padding: 0 3px;}
			span.address {
				width: 62%; font-weight: 700;
				span.city {display: block; font-weight: 400;}
				span.link {
					display: inline-block;
					@include textDec(0,1);
					color: $cOrange;
					font-weight: 500;
				}
			}
			span.right {text-align: right; color: $cBlue;}
			span.distance {
				color: $cBlue;
				display: block;
			}
			span.is-opened, span.is-closed {
				display: block;
				font-weight: 500;
			}
			span.is-opened {
				color: $cGreen;
			}
			span.is-closed {
				color: $cOrange;
			}
			span.delivery-date {
				text-align: center;
			}
		}
		// Detail
		> div {
			display: none; padding: 4px 14px 2px; font-size: 0.9em;
			ul.detail {
				margin: 8px 0;
				li {
					padding: 2px 0;
				}
			}
			ul.services {
				margin: 8px 0;
				li {
					display: inline-block; 
					margin-right: 6px;
					.fa {
						color: $cGreen; 
						font-size: 0.85em;
					}
				}
			}
			p.thumb {
				margin: 8px 0;
				img {
					max-width: 100%;
					height: auto;
					display: inline-block;
				}
			}
			p.links {
				text-align: center;
				margin: 8px 0;
				a.btn {
					display: inline-block;
				}
			}
		}
		&.open {
			background: $hover;
			//> p {display: none;}
			> div {display: block;}
		}
	}
}

label img {
	vertical-align:middle;
}

// Zasilkovna
.zasilkovna-selected {
	font-size: 12px;

	&.hidden {
		display: none !important;
	}
}
