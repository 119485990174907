.how-it-works-boxes {
	margin: 20px -16px; position: relative; padding: 20px 0 10px;
	.item {
		float: left; width: 33.33333333%; padding: 0 36px; position: relative;
		.inner {border: 1px solid $cLine; @include borderRadius(8px); position: relative; padding: 90px 20px 14px;}
		&:before {
			content: ""; display: block; position: absolute; left: -12px; top: 50%; margin-top: -20px;
			background: url('../image/ico.png') -312px -65px no-repeat; height: 40px; width: 24px;
		}
		&:first-child:before {display: none;}
		// obsah
		i.fa {
			left: 50%; 
			top: 30px; 
			margin-left: -40px;
			display: block;
			position: absolute;
			width: 80px;
			text-align: center;
			@include fh(40px, 40px);
			color: $cGreen;
		}
		p {
			text-align: center;
			span.num {display: block; font-weight: bold; @include fh(24px); display: block; color: $cGreen;}
		}
	}
}

.help-title {
	@include fh(24px);
	margin: 40px 0 30px;
	a {
		color: $cOrange;
		font-weight: bold;
		@include textDec(1,0);
	}
}

// More info
.how-it-works-more {
	margin: 0 -26px 20px; position: relative;
	> div {
		margin-bottom: 1px;
		h3 {
			@include fh(22px); font-weight: 400;
			a {display: block; background: $cBlue; @include textDec(0,0); color: #fff; padding: 8px 18px 6px;}
		}
		.inner {padding: 12px 26px; display: none;}
		._text {@include fh(15px, 1.4em);}
		// open
		&.open .inner {display: block;}
		&.active h3 a, h3 a:hover {background: $cGreen;}
	}
}

// Check list
.check-list {
	position: relative;
	text-align: center;
	@include fh(18px);
	ul {
		display: inline-block;
		text-align: left;
		li {
			position: relative;
			padding: 5px 0;
			&:before {
				content: "\f00c";
				font-family: "FontAwesome";
				color: $cGreen;
				font-size: 22px;
				display: inline-block;
				margin-right: 8px;
			}
		}
	}
}

// Reasons box
.reason-boxes {
	margin: 26px 50px 30px;
	//margin: 0 -15px;
	.item {
		float: left;
		width: 50%;
		padding: 6px;
		> div {
			border: 1px solid $cBlue;
			padding: 12px 14px 12px 80px;
			position: relative;
			background: $cLineLight;
			@include borderRadius(4px);
		}
		// icon
		.fa {
			display: block;
			width: 80px;
			text-align: center;
			position: absolute;
			height: 50px;
			@include fh(30px, 50px);
			top: 50%;
			left: 0;
			margin-top: -25px;
			color: $cBlueDark;
		}
		// Title
		h2 {
			@include fh(18px);
			color: $cBlueDark;
			margin: 0 0 6px;
		}
	}
}
