// iCheck
.icheckbox,.iradio{display:block;margin:0;padding:0;width:14px;height:14px;background: url('../image/icheck.png') no-repeat;border:none;cursor:pointer;}
.icheckbox {
	background-position: 0 0;
	&.checked { background-position: -14px 0; }
	&.disabled { background-position: -28px 0; cursor: default; }
	&.checked.disabled { background-position: -42px 0; }
}
.iradio {
	background-position: 0 -14px;
	&.checked { background-position: -14px -14px; }
	&.disabled { background-position: -28px -14px; cursor: default; }
	&.checked.disabled { background-position: -42px -14px; }
}