.coupon-inner {
	.coupon-image-label {
		font-weight: $semibold;
		text-align: left;
		.image {
			display: block;
			position: relative;
			img {
				box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
			}
			span {
				position: absolute;
				left: 0;
				right: 25%;
				text-align: center;
				top: 50%;
				display: block;
				transform: translateY(-50%);
				@include fh(30px, 1em);
				font-weight: $bold;
			}
		}
	}

}

.coupon-preview-container {
	margin-left: -20px;
	margin-right: -20px;
	position: relative;
	.coupon-preview {
		margin-left: auto;
		margin-right: auto;
		position: relative;
		max-width: 800px;
		width: 100%;
		padding: 20px;
		img {
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
		}
		.coupon-content {
			display: block;
			position: absolute;
			left: 12%;
			right: 48%;
			top: 42%;
			text-align: left;
		}
		.coupon-name {
			@include fh(30px, 1em);
			font-weight: $bold;
			margin: 0;
			padding: 0;
		}
		.coupon-text {
			padding: 0;
			margin: 18px 0 0;
			font-weight: $semibold;
			@include fh(14px, 19px);
		}
		.coupon-price {
			display: block;
			position: absolute;
			right: 10%;
			top: 0;
			bottom: 0;
			text-align: center;
			color: $cWhite;
			width: 44px;
			font-weight: $bold;
			> span {
				@include fh(44px, 0px);
				display: block;
				transform: translateX(-50%) rotate(-90deg);
				position: absolute;
				top: 50%;
				left: 50%;
				white-space: nowrap;
				height: 2px;
			}
		}
	}

	@media (max-width: $screen-md-max) {
		.coupon-preview {
			.coupon-name {
				@include fh(22px, 1em);
			}
			.coupon-text {
				margin-top: 10px;
				@include fh(12px, 17px);
			}
		}
	}

	@media (max-width: $screen-sm-max) {
		overflow-x: scroll;
		.coupon-preview {
			width: $screen-sm;
		}
	}
}