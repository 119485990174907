// Specialni font (nadpisy,...)
@mixin _font() {
	font-family: 'Exo', Arial, sans-serif;
}

// Box Shadow
@mixin boxShadow($arg) {
	-moz-box-shadow: $arg;
	-webkit-box-shadow: $arg;
	box-shadow: $arg;
}

// Border radius
@mixin borderRadius($arg:4px) {
	-moz-border-radius: $arg;
	-webkit-border-radius: $arg;
	-khtml-border-radius: $arg;
	border-radius: $arg;
}

// Box sizing
@mixin boxSizing($arg:border-box) {
	-webkit-box-sizing: $arg;
	-moz-box-sizing: $arg;
	-ms-box-sizing: $arg;
    -khtml-box-sizing: $arg;
	box-sizing: $arg;
}

// Vlastnosti ikony
@mixin ico($pH, $pV, $w: null, $h: null) {
	background-position: #{$pH}px #{$pV}px; 
	@if $w != null {width: #{$w}px;}
	@if $h != null {height: #{$h}px;}
}

// Opacity
@mixin opacity($arg) {
	opacity: #{$arg}; 
	filter: alpha(opacity=#{$arg*100});
}

// Vertikalni prechod 2 barev
@mixin gradient($x, $y) {
	background: $y; 
	background: -moz-linear-gradient(top, #{$x} 0%, #{$y} 100%); 
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#{$x}), color-stop(100%,#{$y})); 
	background: -webkit-linear-gradient(top, #{$x} 0%, #{$y} 100%); 
	background: -o-linear-gradient(top, #{$x} 0%, #{$y} 100%); 
	background: -ms-linear-gradient(top, #{$x} 0%, #{$y} 100%); 
	background: linear-gradient(to bottom, #{$x} 0%, #{$y} 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$x}', endColorstr='#{$y}',GradientType=0);
}

// Horizontalni prechod 2 barev
@mixin gradientHorizontal($x, $y, $bg) {
	background: $bg; 
	background: -moz-linear-gradient(left, #{$x} 0%, #{$y} 100%); 
	background: -webkit-gradient(linear, left top, right top, color-stop(0%,#{$x}), color-stop(100%,#{$y})); 
	background: -webkit-linear-gradient(left, #{$x} 0%, #{$y} 100%); 
	background: -o-linear-gradient(left, #{$x} 0%, #{$y} 100%); 
	background: -ms-linear-gradient(left, #{$x} 0%, #{$y} 100%); 
	background: linear-gradient(to right, #{$x} 0%, #{$y} 100%); filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$x}', endColorstr='#{$y}',GradientType=1);
}

// Podtrzeni odkazu a jeho hover
@mixin textDec($d: 1, $h: 0) { 
	@if $d == 1 {text-decoration: underline;}
	@else {text-decoration: none;}
	@if $h == 1 {&:hover {text-decoration: underline;}}
	@else {&:hover {text-decoration: none;}}
}

// Nastaveni vlastnosti vsem nadpisum
@mixin headings {
	h1, h2, h3, h4, h5, h6 {@content;}
}

// Velikost pisma a jeho vyska
@mixin fh($size, $line-height: 1.3em) {
	font-size: $size;
	line-height: $line-height;
}

@mixin image(){
	display: inline-block; max-width: 100%; height: auto;
}

@mixin rotate($arg)
{
	-webkit-transform: rotate($arg);
    -moz-transform: rotate($arg);
    -ms-transform: rotate($arg);
    -o-transform: rotate($arg);
    transform: rotate($arg);
}


@mixin grayscale($val: 100%)
{
	filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
    filter: gray;
    -webkit-filter: grayscale($val);
}


@mixin transition($val...)
{
	-webkit-transition: $val;
	-moz-transition: $val;
	-o-transition: $val;
	transition: $val;
}