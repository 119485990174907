/**
 * Screen size
 */
$screen-xlg: 100%;
$screen-lg: 980px;
$screen-md: 780px;
$screen-sm: 600px;
$screen-xs: 500px;
$screen-xxs: 390px;
// Min
$screen-min: 320px;

// Minimal
$screen-xlg-min: $screen-lg + 1;
$screen-lg-min: $screen-md + 1;
$screen-md-min: $screen-sm + 1;
$screen-sm-min: $screen-xs + 1;
$screen-xs-min: $screen-xxs + 1;
$screen-xxs-min: 0;

// Maximal
$screen-xlg-max: 100%;
$screen-lg-max: $screen-lg;
$screen-md-max: $screen-md;
$screen-sm-max: $screen-sm;
$screen-xs-max: $screen-xs;
$screen-xxs-max: $screen-xxs;

/**
 * Modernizer classes
 */
// Touch
$touch: ".touchevents";
$no-touch: ".no-touchevents";
// CSS collumns
$csscolumns: ".csscolumns";
$no-csscolumns: ".no-csscolumns";

$bold: 700;
$semibold: 500;
$normal: 400;