// Margins
$margin-xxs	: 2px !default;
$margin-xs	: 4px !default;
$margin-sm	: 8px !default;
$margin-md	: 14px !default;
$margin		: 20px !default;
$margin-lg	: 32px !default;
$margin-xlg : 48px !default;


// Cols
$gridCols 		: 12 !default;
$gridSizes 		: xxs xs sm md lg xlg !default;
// Indent
$gridIndent-xlg	: 24px !default;
$gridIndent-lg	: 18px !default;
$gridIndent		: 14px !default;
$gridIndent-md	: 10px !default;
$gridIndent-sm	: 8px !default;
$gridIndent-xs	: 6px !default;
$gridIndent-xxs	: 4px !default;
$gridSizesIndent : $gridIndent-xxs $gridIndent-xs $gridIndent-sm $gridIndent-md $gridIndent-lg $gridIndent-xlg !default;