/**
 * HP - main outher
 */
.homepage .main-outer {
	padding-top: 0;
	padding-bottom: 0;
}

._lightbox-page-select .frm  {
	@media (max-width: $screen-sm-max) {
		.inner {
			padding-left: 0;
			padding-right: 0;
		}
		p.chck-wrap {
			width: (100% / 4);
		}
	}
	@media (max-width: 420px) {
		p.chck-wrap {
			width: (100% / 3);
		}
	}
}

/**
 * Homepage top slide
 */
.hp-top {
	padding: 52px 18px 26px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.content {
		width: 50%;
	}
	// Main title
	h1 {
		color: $cOrange;
		@include fh(30px);
		font-weight: 700;
		margin-bottom: 30px;
	}
	// Img
	.img {

	}
	img {
		display: block;
		height: auto;
		width: auto;
		max-width: 100%;
	}
	@media (max-width: $screen-md-max) {
		padding: 30px 18px;
		flex-direction: column;
		h1 {
			text-align: center;
			margin: 0;
		}
		ul.default-check-list {
			display: none;
		}
		img {
			height: 200px;
		}
	}
	@media (max-width: $screen-sm-max) {
		h1 {
			@include fh(24px);
		}
		img {
			height: 180px;
		}
	}
}

/**
 * Homepage quarantee
 */
.hp-guarantee {
	background: $cGreen;
	color: #fff;
	text-align: center;
	position: relative;
	@include fh(18px, 24px);
	padding: 8px;
	p {
		width: 50%;
		&.pull-left {
			padding-right: 50px;
			text-align: right;
		}
		&.pull-right {
			padding-left: 50px;
			text-align: left;
		}
	}
	img {
		display: block;
		position: absolute;
		z-index: 10;
		left: 50%;
		top: 50%;
		margin: -40px 0 0 -40px;
	}
	a {
		color: #fff;
		@include textDec(1, 0);
	}
}

@media (max-width: $screen-md-max) {
	.hp-guarantee {
		text-align: center;
		p {
			width: auto;
			float: none !important;
			display: inline-block;
			padding: 0 !important;
		}
		img {
			display: none;
		}
	}
}

@media (max-width: $screen-sm-max) {
	.hp-guarantee {
		p.pull-left {
			display: none;
		}
	}
}

/**
 * Homepage container
 */
.white-container {
	background: #fff;
	padding: 40px 0;
	&.hp-container {
		padding: 20px 0;
	}
}

.bg-container {
	background: #fff url('../image/bg.png') 50% 0 no-repeat;
	padding: 30px 0;
}

/**
 * Offering item
 */
.offering-item {
	padding: 32px 0;
	// Thumb
	.thumb {
		float: left;
		width: 25%;
		a, span {
			display: inline-block;
		}
		a {
			display: inline-block;
			&:hover img {
				border-color: $cBlueDark;
			}
		}
		img {
			display: block;
			max-width: 100%;
			height: auto;
			border: 1px solid $cLine;
			@include borderRadius(4px);
		}
	}
	// Text
	.right {
		float: right;
		width: 75%;
		padding-left: 18px;
	}
	// Titles
	h2 {
		color: $cOrange;
		@include fh(18px);
	}
	h3 {
		color: $cBlueDark;
		@include fh(14px);
	}
	// Descript
	.txt {
		padding: 14px 0;
	}
	// Bottom
	.item-bottom {
		p {
			float: left;
			padding-right: 30px;
			&.btn-wrap {
				padding-right: 0;
				float: right;
			}
		}
		.item-fa {
			position: relative;
			margin-top: 12px;
			padding-left: 16px;
			.fa {
				position: absolute;
				left: 0;
				top: 1px;
				display: block;
			}
			a {
				color: $cGreen;
				@include textDec(1, 0);
				font-weight: bold;
			}
		}
		.item-fa-money {
			padding-left: 22px;
		}
	}
}

@media (max-width: $screen-md-max) {
	.offering-item {
		text-align: center;
		border-bottom: 1px solid $cLine;
		&:last-child {
			border-bottom: none;
		}
		.thumb, .right {
			float: none;
			width: auto;
		}
		.right {
			padding: 14px 0 0;
		}
		.item-bottom {
			p {
				float: none;
				display: inline-block;
			}
			.item-fa {
				padding: 0;
				margin: 0 6px;
				.fa {
					position: relative;
					display: inline-block;
					left: auto;
					top: auto;
					margin-right: 2px;
				}
				br {
					display: none;
				}
				a {
					display: inline-block;
					margin-left: 10px;
				}
			}
			p.btn-wrap {
				padding-top: 16px;
				float: none;
				clear: both;
				display: block;
				text-align: center;
			}
		}
	}
}

#cboxPrevious,#cboxNext,#cboxSlideshow,#cboxClose{color: transparent;}


/**
 * Offering item medium size
 */
.offering-items-md-container {
	position: relative;
	.offering-item-md {
		float: left;
		width: 50%;
		padding: 20px;
	}
}

.offering-item-md {
	// Border
	.inner {
		border: 1px solid $cLine;
		@include borderRadius();
		padding: 18px 12px;
		text-align: center;
	}
	// Titles
	h3 {
		color: $cOrange;
		@include fh(18px);
	}
	h4 {
		color: $cBlueDark;
		@include fh(14px);
		margin-bottom: 20px;
	}
	// Descript
	.txt {
		padding: 14px 0;
	}
	// Bottom
	.item-bottom {
		p {
			margin-top: 18px;
		}
		.item-fa {
			margin-top: 0;
			.fa {
				display: inline-block;
				margin-right: 2px;
			}
			a {
				color: $cGreen;
				@include textDec(1, 0);
				font-weight: bold;
			}
		}
	}
}

@media (max-width: $screen-sm-max) {
	.offering-items-md-container {
		.offering-item-md {
			width: 100%;
			padding: 0;
			.inner {
				height: auto !important;
				padding: 30px 0;
				border: none;
				border-bottom: 1px solid $cLine;
				@include borderRadius(0);
			}
		}
	}
}

/**
 * Bottom
 */
.bottom-beta {
	background: $bg;
	padding: 14px 0;
	@include fh(15px);
	h2 {
		color: $cOrange;
		@include fh(18px);
	}
	p, h2 {
		margin: 22px 0;
	}
	p {
		font-weight: 500;
	}
	.btn-group {
		margin-bottom: 22px;
	}
}

@media (max-width: $screen-sm-max) {
	.bottom-beta {
		.btn-group {
			p {
				float: left;
				width: 50%;
			}
		}
	}
}

/**
 * Reference
 */
.reference-outer {
	padding: 30px 0;
	max-width: 720px;
	margin: 0 auto;
	position: relative;
	width: 100%;
	text-align: left;
	h2 {
		color: $cOrange;
		padding: 6px 0;
	}
}

.reference {
	.item {
		padding: 20px 0 12px;
		@include fh(15px);
	}
	.thumb {
		float: left;
		width: 25%;
		img {
			display: block;
			@include borderRadius(50%);
			border: 1px solid $cLine;
			max-width: 100%;
			height: auto;
		}
	}
	.right {
		float: left;
		width: 75%;
		padding-left: 26px;
		.txt {
			font-style: italic;
			p {
				margin: 12px 0;
			}
		}
	}
	.author {
		font-weight: bold;
		a {
			color: $cBase;
			@include textDec(1, 0);
		}
	}
	.school {
		text-transform: uppercase;
		color: $cGreen;
	}
}

@media (max-width: $screen-sm-max) {
	.reference {
		text-align: center;
		.thumb, .right {
			float: none;
			width: auto;
		}
		.thumb img {
			display: inline-block;
		}
		.right {
			padding: 6px 20px 0;
		}
	}
}

/**
 * Boxy jak to funguje
 */
.title-how {
	padding-top: 38px;
}

.how-it-works-boxes-beta {
	margin: 20px 0;
	position: relative;
	padding: 20px 0 10px;
	.item {
		float: left;
		width: 33.33333333%;
		padding: 0 22px;
		position: relative;
		text-align: center;
		// obsah
		h2 {
			display: block;
			font-weight: bold;
			@include fh(18px);
			color: $cBase;
		}
		i.fa {
			display: inline-block;
			@include fh(72px, 1.6em);
			color: $cGreen;
		}
		p.txt {
			font-weight: 500;
		}
	}
}

@media (max-width: $screen-md-max) {
	.how-it-works-boxes-beta {
		.item {
			padding: 0 12px;
			p.txt {
				display: none;
			}
		}
	}
}

@media (max-width: $screen-sm-max) {
	.how-it-works-boxes-beta {
		display: none;
	}
}

/**
 * Gallery
 */
.gallery-beta {
	margin: 40px -4px;
	position: relative;
	li {
		float: left;
		width: 25%;
		padding: 8px;
	}
	a {
		display: block;
		position: relative;
		img {
			@include borderRadius();
			border: 1px solid $cLine;
			padding: 2px;
			@include image();
			display: block;
		}
		&:before {
			background: rgba(#fff, 0.9);
			position: absolute;
			left: 50%;
			top: 50%;
			margin: -30px 0 0 -30px;
			@include borderRadius(50%);
			font-family: "FontAwesome";
			@extend .fa-search:before;
			@include fh(24px, 58px);
			text-align: center;
			width: 60px;
			height: 60px;
			color: $cOrange;
			display: none;
		}
		&:hover {
			&:before {
				display: block;
			}
		}
	}
	&.gallery-beta-how {
		margin-left: auto;
		margin-right: auto;
		max-width: 880px;
	}
}

@media (max-width: $screen-md-max) {
	.gallery-beta {
		li {
			width: 33.333333%;
		}
		.hidden-md {
			display: none;
		}
	}
}

@media (max-width: $screen-xs-max) {
	.gallery-beta {
		li {
			width: 50%;
		}
		.hidden-xs {
			display: none;
		}
	}
}

@media (max-width: $screen-xxs-max) {
	.gallery-beta {
		margin: 20px 0;
		li {
			width: 100%;
			text-align: center;
			a {
				display: inline-block;
			}
		}
		.hidden-xxs {
			display: none;
		}
	}
}

/**
 * Seznam garanci
 */
.guarantee-list {
	background: url('../image/beta/guarantee-lg.png') 100% 50% no-repeat;
	padding-right: 240px;
	margin: 30px 0;
	li {
		position: relative;
		padding: 20px 0 20px 60px;
		font-weight: 500;
		@include fh(16px);
		span {
			display: block;
			position: absolute;
			left: 0;
			top: 4px;
			width: 48px;
			height: 48px;
			text-align: center;
			color: $cGreen;
			border: 2px solid $cGreen;
			@include borderRadius(50%);
			@include fh(30px, 46px);
			font-weight: 500;
		}
	}
}

@media (max-width: $screen-md-max) {
	.guarantee-list {
		background-image: none;
		padding-right: 0;
	}
}
