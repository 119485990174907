i.ico 			{display: block; background: transparent url('../image/ico.png') 50px 50px no-repeat; position: absolute; width: 50px; height: 50px;}
i.icoFb			{@include ico(   0,  0, 28, 28);}
i.icoFbMini		{@include ico( -88,  0, 20, 20);}
i.icoFbLarge	{@include ico(-311,-28, 16, 36);}
i.icoCoffe		{@include ico(   0,-28, 35, 37);}
i.icoMail		{@include ico( -28,-14, 14, 14);}
i.icoMapPoint 	{@include ico(-116,  0, 12, 16);}
i.icoPhone		{@include ico( -28,  0, 14, 14);}
i.icoSupplyPostomat		{@include ico(-128,  0, 17, 17);}
i.icoSupplyGeispoint	{@include ico(-269,  0, 17, 17);}
i.icoSupplyStore		{@include ico(-145,  0, 17, 17);}
i.icoSupplyZasilkovna	{@include ico(-287,  0, 17, 17);}

// Button ico
i.icoEnvelope	{@include ico( -65,  0, 23, 28);}
i.icoBasket		{@include ico( -42,  0, 23, 28);}
i.icoBasket2	{@include ico(-162,  0, 23, 28);}

// Reason ico
i.icoPickup		{@include ico( -35,-28, 40, 37);}
i.icoTime		{@include ico( -75,-28, 40, 37);}
i.icoContexture	{@include ico(-115,-28, 40, 37);}
i.icoEye		{@include ico(-155,-28, 40, 30);}
i.icoYoutube	{@include ico(-195,-28, 40, 30);}
i.icoMenu 		{@include ico(-235,-28, 39, 34);}
i.icoMenuFocus 	{@include ico(-272,-28, 39, 34);}

// Message
i.icoMessageSuccess 	{@include ico(   0, -65, 40, 40);}
i.icoMessageInfo 		{@include ico( -40, -65, 40, 40);}
i.icoMessageError   	{@include ico( -80, -65, 40, 40);}
i.icoMessageSuccessMini {@include ico(-185,   0, 28, 28);}
i.icoMessageInfoMini 	{@include ico(-213,   0, 28, 28);}
i.icoMessageErrorMini 	{@include ico(-241,   0, 28, 28);}

// How it works
i.icoBook		{@include ico(-120,-65, 64, 61);}
i.icoPhone2		{@include ico(-184,-65, 64, 61);}
i.icoStudent	{@include ico(-248,-65, 64, 61);}
