@media (min-width: 981px) {
	#header #main-menu {display: block !important;}
}
@media (max-width: 980px) {	
	@at-root body.responsive-content {min-width: 360px;}
	.wrap {min-width: 360px; width: auto; margin-left: auto; margin-right: auto;}
	.wrap-padding {padding-left: 20px; padding-right: 20px;}
	.wrap-margin {margin-left: 20px; margin-right: 20px;}
	.responsive-content {max-width: 500px; margin-left: auto; margin-right: auto;}

	/**
	 * Header
	 */
	#header {
		.logo {padding-top: 10px; padding-left: 20px;}
		p#menu-toggle {display: block;}
		#main-menu {
			display: none;
			float: none; clear: both; background: url('../image/main-menu-separ.png') 50% 0 no-repeat; 
			text-align: center; padding-top: 8px; padding-bottom: 8px;
			> ul {
				> li {
					@include fh(14px); padding: 2px 0;
					float: none;
					> a {
						height: auto; padding: 12px 0; font-weight: 500;
						&:before {display: none;}
						&:hover {background: #d6e3eb !important;}
					}
					&.li-order {
						a {color: $cOrange; background: none;}
					}
					&.li-fb {
						> a {
							width: auto;
							> span {
								position: relative; display: inline-block; padding-left: 40px;
								> span {display: inline-block;}
								i.ico {left: 0; margin-left: 0;}
							}
						}
					}
				}
			}
		}
	}


	/**
	 * Homepage
	 */
	.hp-slide {
		padding-left: 16px; padding-right: 16px;
		ul.reasons {padding-left: 0; padding-right: 0;}
	}
	.review-outer {padding-left: 0; padding-right: 0;}
	.bottom .boxes > div, .hp-bottom-boxes > div {float: none; width: auto;}
	.hp-bottom-boxes > div:first-child:before {display: none;}
}
@media (max-width: 1340px) {
	.banner-wrapper-fixed {
		display: none;
	}
}

@media (min-width: 781px) {
	.banner-image.mobile {}