// Cols with offset too
@mixin CoreGrid($size, $min, $cols: 12)
{
	@media (min-width: $min)
	{
		.offset-bcol-#{$size}-0 {
			margin-left: 0;
		}
		@for $i from 1 through $cols {
			// Col
			.bcol-#{$size}-#{$i} {
				width: $i * 100% / 12;
			}
			// Offset
			.offset-bcol-#{$size}-#{$i} {
				margin-left: $i * 100% / 12;
			}
		}
	}
}


// Grid sizes
@mixin CoreGridSize($class, $indentation)
{
	.#{$class} {
		margin-left: -$indentation;
		margin-right: -$indentation;
		position: relative;
		> [class^="bcol-"], > [class*=" bcol-"] {
			padding-left: $indentation;
			padding-right: $indentation;
		}
		&:before, &:after {
			content: " ";
			display: table;
		}
		&:after {
			clear: both;
		}
	}
}



// For each grid
[class^="bcol-"], [class*=" bcol-"] {
	float: left;
	position: relative;
	padding-left: $gridIndent;
	padding-right: $gridIndent;
	min-height: 1px;
	width: 100%;
}

// Default grid sizes
@include CoreGridSize("brow", $gridIndent);

// Add grid for each size
$i: 1;
@each $size in $gridSizes
{
	@include CoreGrid($size, __ScreenGetMin($size), $gridCols);
	@include CoreGridSize("brow-#{$size}", nth($gridSizesIndent, $i));
	$i: $i + 1;
}
