@media (max-width: 500px) {	
	._mode-full, ._mode-tablet {display: none;}
	._mode-mobile {display: block;}

	/**
	 * Homepage
	 */
	.hp-slide {
		h1 {@include fh(26px);}
		ul.reasons {padding-top: 0;}
	}
	.hp-bottom-boxes {margin-left: 8px; margin-right: 8px;}
	.bottom .boxes {
		margin-left: 0; margin-right: 0;
		h2 span {width: auto; margin-left: 0; @include borderRadius(0);}
		> div {
			padding-left: 0; padding-right: 0;
			p {padding-left: 18px; padding-right: 18px;}
		}
	}
	.review-outer {
		margin-left: 0; margin-right: 0; @include borderRadius(0);
		.review-inner .right {width: 77%;}
	}
}