// Obecne
$_minWidth 		: 980px;
$_width 		: 980px;

// pozadi
$bg 			: #d6e3eb;

// barvy textu
$cWhite 		: #fff;
$cBase 			: #374047;
$cBlue 			: #8db2c5;
$cBlue2 		: #627b8e;
$cBlueDark 		: #1a3445;
$cGreen 		: #65bb4c;
$cOrange 		: #e95241;
$cRed 			: #D11B19;
$cLink			: $cOrange;
$cLine 			: #8db2c5;
$cLineLight		: #eaf1f5;


$cGreenLight 	: #e1f2e7;

$gridIndent: 15px;


// Pomocne styly
%minWidth {min-width: $_minWidth;}
