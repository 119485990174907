/*
Author:			Martin Adamec
Author URL:		http://www.adamecmartin.cz
*/
@charset "UTF-8";
@import "font-awesome";
@import "function", "variable", "base", "ico", "form", "message";
@import "colorbox", "lightbox", "icheck", "select2";
@import "skeleton", "text", "home", "contact", "annotation" , "order", "pricelist", "how-it-works", "progressbar";
// Beta
@import "beta/__init__";




/**
 * Responsive
 */
._mode-tablet, ._mode-mobile {display: none;}
._mode-full {display: block;}
.responsive-content {
	@import "responsive/max", "responsive/780", "responsive/600", "responsive/500", "responsive/390";
}
