/**
 * iRadio wrap
 */
.frm {
	.iRadio-container {
		margin: 20px 0;
		> p {
			margin: 0;
			padding: 0;
		}
		.iRadio-wrap {
			float: left;
			padding-top: 1px;
			padding-left: 2px;
		}
		.iRadio-text {
			padding-left: 24px;
			p {
				margin: 0 0 6px;
				padding: 0;
			}
			label.lbl {
				float: none;
				padding: 0;
				width: auto;
				cursor: pointer;
			}
		}
		&[data-theme="sm"] {
			margin: 6px 0;
		}
	}
}


/**
 * File upload
 */
.frm .file-upload-wrap {
	background: $cLineLight;
	border-radius: 4px;
	border: 1px solid $cLine;
	padding: 14px 16px;
	label.lbl {
		float: none;
		width: auto;
		cursor: pointer;
		padding: 0 0 4px;
	}
	.iRadio-container {
		margin: 8px 0;
	}
}

/**
 * Arrows
 */
.frm-count-arrows-container {
	display: flex;
	align-items: center;
	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	.arrows {
		display: flex;
		flex-direction: column;
		padding-left: 8px;
		a {
			display: block;
			@include fh(14px, 12px);
			color: $cBlue2;
			i {
				line-height: 12px;
			}
			&:hover {
				color: $cBlueDark;
			}
		}
	}
}

.frm {
	.lbl.lbl-full {
		float: none;
		width: auto;
	}
	textarea.tArea.tArea-sm {height: 60px;}
}