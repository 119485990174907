.guide {
	padding-bottom: 50px;
	@media (max-width: 700px) {
		position: relative;
		margin-top: -30px;
	}
}

// First step
.guide-step-01 {
	position: relative;
	text-align: center;
	margin-top: 40px;
	&:before {
		content: "";
		position: absolute;
		display: block;
		border-left: 1px solid $cLineLight;
		left: 50%;
		top: 42px;
		bottom: 50px;
	}
	&.is-selected:before {
		bottom: 20px;
	}

	.item {
		width: 50%;
		float: left;
		background: transparent url('../image/guide-step-01-bg.png') 50% 100% no-repeat;
		padding: 20px 50px 40px;
	}

	// Content
	.title {
		position: relative;
		img.img-main {
			width: 80px;
			height: 80px;
			margin-bottom: 10px;
		}
		img.img-sub {
			width: 30px;
			height: 30px;
			display: block;
			position: absolute;
			left: 50%;
			top: 0;
			transform: translateX(10px);
		}
		a {
			color: $cOrange;
			display: block;
			@include fh(30px, 1.05em);
			font-weight: $semibold;
			@include textDec(0,0);
			span {
				border-bottom: 2px solid $cOrange;
				display: inline-block;
			}
			&:hover span {
				border-bottom-color: transparent !important;
			}
		}
	}
	.prologue {
		color: $cBlueDark;
		@include fh(18px, 1.2em);
		padding: 8px 0;
	}
	ul {
		color: $cBlueDark;
		text-align: left;
		padding: 20px 0 18px;
		@include fh(14px, 1.3em);
		font-weight: $semibold;
		display: inline-block;
		li {
			padding: 2px 0;
			&:before {
				content: "+";
				display: inline-block;
				margin-right: 4px;
			}
		}
	}
	.info {
		border-top: 1px solid $cLineLight;
		padding: 16px 0;
		font-weight: $semibold;
		@include fh(18px, 1.2em);
		p {
			display: inline-block;
			+ p {
				margin-left: 18px;
			}
			a {
				color: $cGreen;
				display: inline-block;
				margin-left: 4px;
				@include textDec(1,0);
				font-weight: $bold;
				font-size: 14px;
			}
		}
	}
	.btn-wrap {
		padding-top: 14px;
		position: relative;
		.btn {
			max-width: 100%;
			width: 170px;
		}
		.btn-absolute {
			color: $cOrange;
			@include textDec(1,0);
			display: block;
			position: absolute;
			left: 0;
			top: 100%;
			right: 0;
			font-weight: $bold;
			margin-top: 8px;
			@include fh(13px);
		}
	}

	// Active item
	.item.active {
		background-image: none;
		padding-bottom: 20px;
		ul, .info, .btn-wrap {
			display: none;
		}
		.prologue {
			border-bottom: 2px solid $cGreen;
			padding-bottom: 20px;
		}
		.title a span {
			border-bottom-color: transparent;
		}
	}
	.item.inactive {
		background-image: none;
		padding-bottom: 20px;
		ul, .info, .btn-wrap {
			display: none;
		}
		.title a {
			@include fh(22px);
		}
		.prologue {
			@include fh(14px);
		}
		.title a, .prologue {
			color: #8c8c8c;
		}
		.title a span {
			border-bottom-color: #8c8c8c;
		}
		.title img {
			filter: grayscale(100%);
			opacity: 0.8;
			padding: 12px;
		}
		&:hover {
			.title a {
				color: $cOrange;
				border-bottom-color: $cOrange;
			}
			.prologue {
				color: $cBlueDark;
			}
		}
		.prologue {
			border-bottom: 2px solid transparent;
			padding-bottom: 20px;
		}
	}

	// Responsive
	@media (max-width: 700px) {
		&:before {
			display: none;
		}
		.item {
			float: none;
			padding-left: 0;
			padding-right: 0;
			width: 450px;
			position: relative;
			max-width: 100%;
			margin-left: auto;
			margin-right: auto;
		}
		// Active item
		.item.active, .item.inactive {
			padding-top: 10px;
			padding-bottom: 10px;
			.prologue {
				display: none;
			}
			.title {
				text-align: left;
				padding-left: 80px;
				height: 60px;
				img.img-main {
					width: 60px;
					height: 60px;
					position: absolute;
					margin-bottom: 0;
					left: 0;
					top: 0;
				}
				img.img-sub {
					left: 30px;
					transform: translateX(5px);
					width: 25px;
					height: 25px;
				}
				a {
					line-height: 30px;
					padding-top: 15px;
				}
			}
		}
	}
}

// Step 02
.guide-step-02 {
	display: none;
	.prologue {
		margin: 20px 0;
		@include fh(15px, 1.5em);
	}
	.video-item {
		margin-top: 90px;
		.title {
			@include fh(30px, 1.05em);
			color: $cOrange;
			font-weight: $bold;
		}
		.prologue {
			p {
				margin-bottom: 12px;
			}
		}
	}
	.link-back {
		text-align: center;
		padding: 12px 0;
		display: none;
		a {
			color: $cOrange;
			font-weight: $bold;
			@include textDec(1,0);
		}
	}
	&.is-selected {
		.guide-table {
			margin-left: auto;
			margin-right: auto;
			position: relative;
			max-width: 640px;
			.col {
				width: 50%;
			}
			.col-head {
				display: none;
			}
			.cell {
				display: none;
			}
			.col.inactive {
				.cell-head {
					background-color: #e4eaee;
					color: #91969a;
					a {
						color: #91969a;
						&:hover {
							color: $cGreen;
						}
					}
				}
				&.col-today .cell-head a:hover {
					color: $cOrange;
				}
			}
			.col.active .cell-head a {
				text-decoration: none;
			}
		}
		.guide-before-boxes {
			.item.active {
				background-color: $cGreen;
				color: #fff;
				position: relative;
				.title {
					background-color: $cGreen;
					a {
						color: #fff;
						text-decoration: none;
					}
				}
				&:after {
					font-family: "FontAwesome", sans-serif;
					content: "\f107";
					position: absolute;
					bottom: 14px;
					left: 50%;
					transform: translateX(-50%);
					@include fh(54px, 1em);
					display: block;
				}
				.content {
					p {
						opacity: 0;
					}
				}
			}
			.content {
				ul {
					display: none;
				}
				p {
					padding-top: 0;
				}
			}
		}
	}

	// Responsive
	@media (max-width: 600px) {
		.prologue {
			padding-bottom: 20px;
			border-bottom: 1px solid $cLine;
		}
	}
	@media (max-width: 500px) {
		&.is-selected {
			.guide-table {
				.col {
					border-bottom-width: 0;
					padding-bottom: 0;
					&:not(.col-head) + .col {
						margin-top: 8px;
					}
				}
			}
		}
	}
}

// Table
.guide-table {
	color: $cBlueDark;
	font-weight: $semibold;
	margin: 40px 0 0;
	.col {
		float: left;
		width: 33.33333%;
		border-left: 1px solid $cLineLight;
	}

	// Head
	.col-head {
		border-left-width: 0;
		.cell {
			font-weight: $bold;
		}
	}
	.cell-head {
		position: relative;
		padding: 12px 12px 10px 60px;
		color: #fff;
		background-color: $cGreen;
		i {
			@include fh(30px, 1em);
			display: block;
			position: absolute;
			left: 10px;
			width: 40px;
			text-align: center;
			top: 50%;
			transform: translateY(-50%);
		}
		.title {
			@include fh(18px, 1.1em);
			font-weight: $semibold;
			a {
				color: #fff;
				@include textDec(1,0);
			}
		}
		p {
			padding-top: 4px;
			line-height: 1.2em;
		}
	}

	// Cell
	.col .cell-first {
		padding-top: 20px;
	}
	.cell {
		padding: 10px 12px 9px;
		a:not(.btn) {
			color: $cBlueDark;
			@include textDec(1,0);
		}
		.title {
			font-weight: $bold;
			padding-bottom: 4px;
			display: none;
		}
		.price {
			@include fh(18px, 1.1em);
			color: $cGreen;
			text-align: center;
			padding: 8px 0;
			font-weight: $bold;
		}
		.btn.toggle {
			display: none !important;
			display: none !important;
		}
	}

	// Today
	.col-today {
		.cell-head {
			background-color: $cBase;
		}
		.cell {
			.price {
				color: $cBase;
			}
		}
	}

	// Responsive
	@media (max-width: 600px) {
		margin-top: 30px;
		.col-head {
			display: none;
		}
		.col {
			width: 50%;
		}
		.cell {
			.title {
				display: block;
			}
		}
	}
	@media (max-width: 500px) {
		.col {
			width: auto !important;
			float: none;
			border-left: none;
			border-bottom: 1px solid $cLine;
			padding-bottom: 20px;
			&:not(.col-head) + .col {
				margin-top: 30px;
			}
		}
		.cell, .cell-head {
			height: auto !important;
		}
		.cell {
			.btn {
				margin: 4px 2px;
			}
			.btn.toggle.show {
				display: inline-block !important;
			}
			.btn.toggle.hide {
				display: none !important;
			}
		}
		.col.show-detail {
			.btn.toggle.hide {
				display: inline-block !important;
			}
			.btn.toggle.show {
				display: none !important;
			}
		}
		.col:not(.show-detail) {
			.cell[data-row] {
				display: none;
			}
			.cell .price {
				padding-top: 14px;
				padding-bottom: 0;
			}
		}
	}
}


// Step 03
.guide-step-03 {
	display: none;
	padding-top: 10px;
	.prologue {
		text-align: center;
		margin: 20px 0;
		@include fh(15px, 1.5em);
		p {
			padding-bottom: 4px;
		}
		i {
			@include fh(60px, 1em);
		}
	}
	@media (max-width: 500px) {
		padding-top: 0;
		.prologue {
			margin-top: 10px;
		}
		&.guide-before-place-mobile {
			display: none !important;
		}
	}
}
.guide-box-place {
	border: 1px solid $cLineLight;
	padding: 20px 12px;
	text-align: center;
	float: left;
	color: $cBlueDark;
	width: 20%;
	.title {
		@include fh(24px, 1.0em);
		color: $cOrange;
		font-weight: $bold;
	}
	ul {
		padding: 10px 0 12px;
		li {
			padding: 2px 0;
		}
		a {
			color: $cBlueDark;
			@include textDec(1,0);
		}
	}
	.btn {
		max-width: 100%;
		width: 170px;
	}
	.price {
		padding: 4px 0 18px;
		@include fh(18px, 1.1em);
		font-weight: $semibold;
	}

	// Branch
	&.guide-box-branch {
		.title {
			color: $cBlueDark;
		}
	}

	// Responsive
	@media (max-width: 700px) {
		width: 50%;
	}
	@media (max-width: 500px) {
		width: auto;
		float: none;
	}
}
.guide-box-place-group {
	// Responsive
	@media (min-width: 701px) {
	}
}
.js-select-branch-placeholder {
	margin-top: 20px;
	@media (max-width: 500px) {
		display: none !important;
	}
}
.item-city {
	.guide-branches {
		display: none;
	}
	// Active
	&.active > .guide-box-place {
		background: $cGreen;
		color: #fff;
		position: relative;
		.title {
			color: #fff;
		}
		p {
			opacity: 0;
		}
		&:after {
			font-family: "FontAwesome", sans-serif;
			content: "\f107";
			position: absolute;
			bottom: 14px;
			left: 50%;
			transform: translateX(-50%);
			@include fh(54px, 1em);
			display: block;
		}
	}
	@media (max-width: 500px) {
		position: relative;
		margin-left: auto;
		margin-right: auto;
		max-width: 240px;
		width: 100%;
		&.active .guide-branches {
			display: block;
			margin: 18px 0 30px;
		}
	}
}

// Before boxes
.guide-before-boxes {
	text-align: center;
	.item {
		display: inline-block;
		margin: 20px;
		text-align: center;
		border: 1px solid $cLineLight;
		width: 360px;
		.title {
			background: #e4eaee;
			padding: 12px 12px;
			a {
				color: $cOrange;
				@include textDec(1,0);
				@include fh(20px, 1.1em);
			}
		}
		.content {
			padding: 20px 12px;
			ul {
				color: $cBlueDark;
				text-align: left;
				@include fh(14px, 1.3em);
				font-weight: $semibold;
				display: inline-block;
				li {
					padding: 2px 0;
					&:before {
						content: "+";
						display: inline-block;
						margin-right: 4px;
					}
				}
			}
			p {
				padding-top: 16px;
			}
		}
	}

	// Responsive
	@media (max-width: 900px) {
		.item {
			display: block;
			margin-left: 0;
			margin-right: 0;
			float: left;
			width: 50%;
		}
	}
	@media (max-width: 600px) {
		.item .title {
			a {
				@include fh(18px, 1.15em);
			}
		}
	}
	@media (min-width: 501px) {
		.guide-step-03 {
			display: none !important;
		}
	}
	@media (max-width: 500px) {
		.item {
			display: block;
			float: none;
			margin-left: auto;
			margin-right: auto;
			width: 360px;
			max-width: 100%;
			.content {
				ul {
					height: auto !important;
				}
			}
		}
	}
}
.guide-print-frm {
	> div {
		display: inline-block;
		padding: 4px 5px;
	}
	.inp-wrap {
		width: 300px;
		input.inp {
			height: 48px;
		}
	}
	@media (max-width: 500px) {
		> div {
			display: block;
		}
		.inp-wrap {
			width: 100%;
		}
	}
}