@mixin msg($color) {
	border-color: $color;
	a {color: $color;}
}
// SIDE NAVI
._msg {
	@include fh(14px); @include borderRadius(26px); 
	position: relative; margin: 4px 30px 12px; text-align: left; background: #fff; color: $cBase; padding: 14px 20px 14px 68px !important; min-height: 62px; border: 1px solid $cBlue;
	i.ico {left: 14px; top: 50%; margin-top: -20px;}
	a {color: $cBlueDark; @include textDec(1,0);}
	p {padding: 4px 0;}
	&._msg-error {@include msg($cOrange);}
	&._msg-success {@include msg($cGreen);}
	// Mini
	&._msg-mini {
		min-height: 46px;
		margin: 4px 12px 10px;
		padding: 12px 14px 10px 52px !important;
		i.ico {
			margin-top: -14px;
			left: 12px;
		}
	}
}