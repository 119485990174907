._lightbox-outer {
	position: absolute; top: 0; left: 0; width: 100%; height: 100%; text-align: center; z-index: 5000; display: none;
	._lightbox-bg {position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: #333; background: rgba(#000,0.3); z-index: 4990;}
	h2 {color: $cOrange; @include fh(24px); text-align: center; font-weight: 400; margin-bottom: 20px;}
	._lightbox-wrap {
		@include borderRadius(4px);
		width: 600px; 
		max-width: 100%;
		
		margin: 0 auto; position: relative; background: #fff url('../image/main-bg.png') 50% 100% no-repeat; z-index: 5000; padding: 26px 20px 20px; border-bottom: 1px solid $cOrange; top: 120px;
		a._lightbox-close {
			@include borderRadius(20px); @include fh(14px, 24px); @include textDec(0,0);
			position: absolute; top: -10px; right: -10px; height: 24px; width: 24px; background: #fff; display: block; font-weight: 700;
			&:hover {background: $cOrange; color: #fff;}
		}
		p {margin: 10px 0;}
	}
	.frm {
		text-align: left;
	}
}

// add file
#_lightbox-add-file {
	._lightbox-wrap p {margin: 0;}
	.price {
		text-align: center;
		color: $cOrange;
		@include fh(16px);
		margin-top: 20px !important;
		strong {
			color: $cBase;
		}
	}
}

// Add contexture
#_lightbox-add-contexture {
	.btns-wrap {
		width: 200px;
		margin: 20px auto; padding: 6px 0 0;
		a[class^="btn"] {padding-left: 14px; padding-right: 14px;}
	}
}

// Select colored page
._lightbox-page-select {
	.frm {
		text-align: left; padding: 0 20px;
		.inner {padding: 0 20px;}
		p.chck-wrap {
			float:left; width: 20%; padding: 4px; margin: 0;
			label.lbl {margin-left: 16px;}
		}
		.frm-bottom {
			border-top: 1px solid darken($cLineLight, 8%); margin-top: 18px; padding: 0 20px;
			p.link {
				padding-top: 18px;
				a {font-weight: 700; @include textDec(1,0); @include fh(14px, 20px); color: $cGreen;}
			}
		}
	}
}

#frm-reminder-outer {
	position: fixed; top: 100px; left: -480px; width: 480px; @include fh(15px);
	#frm-reminder-inner {
		@include boxShadow(0 0 6px rgba(0,0,0,0.4));
		background: #fff url('../image/main-bg.png') 50% 100%; z-index: 5000; padding: 26px 20px 20px; border: 1px solid $cLine; border-left: none;
		h2 {color: $cOrange; @include fh(24px); text-align: center; font-weight: 400; margin-bottom: 20px;}
		p {margin: 10px 0;}
		.frm {
			p {text-align: center; margin: 0;}
			label.lbl {font-weight: 700; float: none; width: auto; text-align: center; padding: 2px 0;}
			input.inp {width: 300px; display: inline-block; text-align: center; text-indent: 0;}
			._select {display: inline-block; min-width: 70px; margin: 2px 4px 0;}
			.slct-large {width: 120px;}
		}
	}
	#frm-reminder-toggl {
		@include boxShadow(0 0 6px rgba(0,0,0,0.4));
		width: 48px; position: absolute; right: -48px; top: 0; background: #e95241; color: #fff;
		a {
			display: block; color: #fff; @include textDec(0,0); overflow: hidden;
			strong {display: block; background: darken(#e95241, 10%); @include fh(18px, 48px); font-weight: normal; display: none;}
			span {display: block; @include rotate(-90deg); height: 220px; margin-left: 12px; width: 220px; @include fh(16px, 20px);}
			&:hover {
				background: darken(#e95241, 10%);
			}
		}
	}
	&.open {
		left: 0;
		#frm-reminder-toggl a strong {display: block;}
	}
}