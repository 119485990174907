@media (max-width: 600px) {

	/**
	 * Homepage
	 */
	.hp-slide {
		ul.reasons {
			width: 230px; margin-left: auto; margin-right: auto; padding-bottom: 0;
			li {float: none; width: auto; border: none; padding-top: 12px; padding-bottom: 12px; padding-right: 0px;}
		}
		p.btn-wrap {padding-top: 0;}
	}
	.review-outer {
		padding-top: 10px; padding-bottom: 4px;
		.review-inner {
			> .review {
				float: none; width: auto; padding: 10px 20px; position: relative; text-align: left;
				&:before {background: url('../image/main-menu-separ.png') 50% 0 no-repeat; top: auto; @include rotate(180deg); height: 101px; width: 100%; position: absolute; bottom: 0; left: 0; content: ""; display: block; position: absolute;}
				&.review-last:before {display: none;}
			}
			.right, p.thumb {position: relative; z-index: 10;}
			p.thumb {
				float: left; width: 19%;
				img {@include image();}
			}
			.right {float: right; width: 79%;}
			h3 span {display: inline;}
			p.txt {margin-top: 8px;}
		}
	}

	/**
	 * Supply pooint
	 */
	.supply-point-outer {
		> .left, > .right {
			float: none;
			width: auto;
		}
		> .right {
			padding-left: 0;
			padding-top: 12px;
		}
	}
	#supply-point-list {
		height: 240px;
	}


	/**
	 * Footer
	 */
	#footer .wrap {
		min-width: 0px; width: 320px; text-align: center;
		.boxes {
			> div {float: none !important; width: auto;}
			> .float-right {
				text-align: center !important;
				form p input.footer-inp {width: 190px;}
			}
		}
		p.bepositive a img {display: inline-block;}
	}
}