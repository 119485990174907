.order-info-msg {
	a {
		display: inline-block;
		margin: 0 2px;
		color: $cBase;
		@include textDec(1, 0);
		font-weight: $bold;
	}
}

/**
 * Subtitle
 */
.order-subtitle {
	color: $cOrange;
	font-weight: $bold;
	margin: 16px 0 12px;
	@include fh(16px);
}

/**
 * Subsection
 */
.order-subsection {
	padding-bottom: 16px;
}

/**
 * Grid
 */
.order-left {
	width: 42%;
}

.order-right {
	width: 54%;
}

/**
 * Radio cols
 */
.order-radio-cols {
	margin: 14px 0;
	//#{$no-csscolumns} & {
	.col {
		float: left;
		width: 50%;
	}
	//}
	//#{$csscolumns} & {
	//	column-count: 2;
	//}
	.frm & .iRadio-container {
		margin: 0;
		padding-bottom: 14px;
	}
}

/**
 * Image preview
 */
.order_img-preview {
	padding: 0 !important;
	margin: 14px 0;
	span {
		display: inline-block;
		width: 278px;
		height: 68px;
		border: 1px solid $cLine;
		border-radius: 4px;
		background: $cLineLight 50% 50% repeat;
	}
}

/**
 * Voba zadani razby
 */
.order_mintage-selector {
	margin-top: 14px;
	display: flex;
	align-items: flex-end;
	p {
		margin: 0 !important;
		padding: 0 !important;
		width: 33.33333333%;
		background: $cBlue;
		border-left: 1px solid #fff;
		&:first-child {
			border-left: none;
		}
	}
	label {
		display: block;
		position: relative;
		padding: 14px 6px 12px;
		cursor: pointer;
		background: $cBlue;
		transition: all .25s ease;
		font-weight: $semibold;
		color: #fff;
		@include textDec(1, 0);
		text-align: center;
		&:hover {
			background: $cBlueDark;
		}
	}
	input {
		position: absolute;
		opacity: 0;
	}
	// Selected
	p.active {
		label {
			background: $cOrange;
			@include textDec(0, 0);
		}
	}
}

/**
 * Popis volby zadani vazby
 */
.order_mintage-description {
	background: $cOrange;
	padding: 0 12px;
	color: #fff;
}

/**
 * Plate
 */
.order_plate {
	background: $cLineLight 50% 50% repeat;
	background-size: cover;
	position: relative;
	height: 670px;
	// Line
	&:before {
		content: "";
		position: absolute;
		left: 50px;
		top: 0;
		bottom: 0;
		width: 0;
		overflow: hidden;
		border-right: 1px solid $cLine;
		display: block;
	}
	// Input
	.plate_inp {
		height: 36px;
		display: block;
		width: 100%;
		padding: 0;
		text-align: center;
		border: 1px solid $cLine;
		background: #fff;
		border-radius: 4px;
		font-size: 15px;
		color: $cBlueDark;
	}

	// Responsive
	@media (max-width: $screen-sm-max) {
		&[data-mintage="upload"], &[data-mintage="no"] {
			&:before {
				display: none;
			}
			height: auto;
		}
	}
}

/**
 * Plate form
 */
.order_plate-form {
	[data-HSS="0"] {
		display: block;
	}
	[data-HSS="1"] {
		display: none;
		background: $cWhite url('/image/beta/cb-hss-plate-sticker.jpg') 0 0 no-repeat;
		padding-top: 81px;
		width: 100%;
		max-width: 300px;
		position: absolute;
		right: 30px;
		top: 30px;
		.plate_inp {
			text-align: left;
			text-indent: 8px;
		}
		.work-type {
			background-color: #2f2c2d;
			padding: 12px 10px;
			p {
				padding: 0;
			}
		}
		.work-content {
			padding: 12px 10px;
			.year {
				text-align: right;
				.plate_inp {
					width: 50%;
					display: inline-block;
				}
			}
		}
		@media (max-width: 400px) {
			top: 10px;
			right: 10px;
			max-width: 270px;
			background-size: 270px;
			padding-top: (81px * (270/300));
		}
	}
	height: inherit;
	// Left - hrbet
	.left {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		input {
			transform: rotate(-90deg);
			position: absolute;
			left: -125px;
			top: 138px;
			width: 300px;
			&.plate_inp-left {
				padding-left: 8px;
				text-align: left;
				top: auto;
				bottom: 138px;
			}
			&.plate_inp-right {
				padding-right: 8px;
				text-align: right;
			}
		}
	}
	// Right - inputs
	.right {
		padding: 16px 12px 0 63px;
		.pull {
			width: 38%;
		}
	}
	.plate-section {
		margin-bottom: 12px;
	}
	// Logo
	.plate-section-logo {
		margin-top: 24px;
		background: transparent 50% 0 no-repeat;
		background-size: 100px;
		padding-top: 120px;
	}
	.plate-section-logo_utb {
		margin-top: 0;
		background: transparent -5px 0 no-repeat;
		background-size: 70px;
		display: none;
		float: left;
		width: 14%;
		padding-top: 120px;
	}

	// UTB
	&.order_plate-form_utb {
		.plate-section-top {
			float: right;
			width: 85%;
		}
		.plate-section-logo {
			background-image: none !important;
		}
		.plate-section-logo_utb {
			display: block;
		}
	}
}

/**
 * Plate upload
 */
.order_plate-upload {
	padding: 16px 12px 16px 64px;
	text-align: center;
	position: relative;
	z-index: 10;
	@media (max-width: $screen-sm-max) {
		padding: 0;
		.file-upload-wrap {
			border-width: 0 !important;
		}
	}
}

/**
 * Table with files
 */
.order_files {
	width: 100%;
	margin: 16px 0;
	border-collapse: collapse;
	thead {
		th {
			text-align: center;
			font-weight: $bold;
			background: $cLineLight;
			border-top: 1px solid $cLine;
			border-bottom: 1px solid $cLine;
			padding: 8px 0;
		}
	}
	tbody {
		td {
			padding: 8px 6px;
			background: #fff;
			border-bottom: 1px solid $cLine;
		}
	}
	label {
		cursor: pointer;
	}
	// Checkbox
	.icheckbox {
		display: inline-block;
	}
	// input mini
	.inp-sm {
		width: 70px !important;
		display: inline-block !important;
	}
	// Link
	a.preview {
		color: $cGreen;
		font-weight: $bold;
		@include textDec(1, 0);
	}
	a.remove {
		font-size: 18px;
		&:hover {
			color: $cGreen;
		}
	}
}

/**
 * Responsive order files table
 */
.tbl-order-responsive {
	@media (max-width: $screen-md-max) {
		border-top: 2px solid $cLine;
		thead {
			display: none;
			th {
				display: none;
			}
		}
		tbody {
			tr {
				border-bottom: 2px solid $cLine;
			}
			td {
				display: block;
				position: relative;
				border-bottom-color: $cLineLight;
				&[data-th] {
					padding: 12px 8px 9px 0;
					text-align: left !important;
					&:before, &:after {
						width: 30%;
					}
					&:before {
						content: attr(data-th)": ";
						color: $cBlueDark;
						font-weight: $bold;
						display: inline-block;
						position: relative;
						z-index: 1;
						padding: 0 8px 0;
						box-sizing: border-box;
						margin-right: 14px;
					}
					&:after {
						content: "";
						border-right: 1px solid $cLineLight;
						background-color: lighten($cLineLight, 4%);
						display: block;
						position: absolute;
						left: 0;
						top: 0;
						bottom: 0;
					}
				}
				&.td-remove {
					padding: 6px;
					border-bottom-width: 0;
					text-align: right;
					a.remove {
						@include fh(22px, 1em);
						display: inline-block;
						border-radius: 4px;
						text-align: center;
						width: 120px;
						padding: 8px 0 6px;
						background-color: $cOrange;
						color: $cWhite;
						&:hover {
							background-color: $cBlueDark;
							color: $cWhite;
						}
					}
				}
			}
		}
		.inp-sm {
			height: 30px !important;
		}
	}
	@media (max-width: $screen-xs-max) {
		tbody {
			td, th {
				&[data-th] {
					&:before, &:after {
						width: 35%;
					}
				}
			}
		}
	}
}


/**
 * Next file
 */
.order_add-file {
	padding: 2px 0 2px !important;
}

/**
 * Extra items to thesis
 */
.order_extra {
	margin: 0 -14px;
	position: relative;
	.item {
		float: left;
		padding: 0 14px 20px;
		width: 33.333333%;
	}
	// Input for count
	.inp-wrap {
		label.lbl {
			float: left !important;
			padding: 7px 8px 0 0 !important;
			min-width: 120px;
		}
		.inp-count {
			width: 70px !important;
			float: left;
			height: 32px !important;
		}
	}
	// Radio container
	.order_extra-content {
		margin-bottom: 20px 0 8px;
		.iRadio-text .lbl.bold {
		}
		p, .inp-wrap {
			margin: 0 0 6px;
			padding: 0;
			&.name {
				color: $cOrange;
				font-weight: bold;
				@include fh(16px);
			}
		}
	}
	// Thumb
	.thumb {
		a {
			display: block;
			&:hover img {
				border-color: $cOrange;
			}
		}
		img {
			border: 1px solid $cLine;
			border-radius: 4px;
		}
	}

	// Responsive
	@media (max-width: $screen-sm-max) {
		.item {
			width: 50%;
		}
		.thumb img {
			width: auto;
			max-height: 120px;
		}
	}
	@media (max-width: $screen-xxs-max) {
		.order_extra-content {
			height: auto !important;
		}
		.item {
			width: 100%;
		}
	}
}

/**
 * Order text
 */
.order_text {
	span {
		color: $cOrange;
	}
	big {
		font-weight: $bold;
		font-size: 1.3em;
		display: inline-block;
		padding: 0 4px;
	}
	a {
		color: $cGreen;
		font-weight: $bold;
		@include textDec(1, 0);
	}
}

/**
 * Total price
 */
.order_total-price {
	@include fh(24px);
	text-align: right;
}

/**
 * Coupon
 */
.order_total-coupon {
	width: 50%;
	.coupon-wrap {
		input.inp {
			width: 210px;
			margin-right: 8px;
		}
	}
}

/**
 * Delivery info
 */
.order_section-delivery-info {
	background: $cGreen url('../image/beta/bg-green.jpg') 50% 0 no-repeat;
	text-align: center;
	padding-top: 36px !important;
	padding-bottom: 30px !important;
	color: #fff;
	@include fh(18px);
	.fa {
		@include fh(30px);
	}
	p {
		padding: 4px 0 !important;
	}
}

/**
 * Customer data
 */
.order_customer {
	position: relative;
	margin: 0 -8px;
	.item {
		padding: 8px;
		float: left;
		width: 45%;
	}
	// Form elements
	.frm & {
		.frm-tArea-wrap {
			width: 64%;
		}
		.frm-inp-wrap {
			width: 44%;
		}
		.frm-inp-wrap, .frm-tArea-wrap, .frm-inp-wrap-sm {
			& + span {
				padding-left: 6px;
			}
		}
		// iRadio
		.iRadio-container {
			margin: 8px 0;
		}
	}
	// Tooltip
	.tooltip {
		display: block;
		float: right;
		padding-left: 0 !important;
		margin-top: 11px;
	}

	// Responsive
	@media (max-width: $screen-lg-max) {
		margin-bottom: 20px;
		.item {
			width: 50%;
		}
		label.lbl {
			float: none;
			width: auto;
		}
		.frm & {
			.frm-tArea-wrap {
				width: calc(100% - 30px);
			}
			.frm-inp-wrap {
				width: calc(70% - 30px);
			}
			.frm-inp-wrap-sm {
				width: 30%;
			}
		}
	}
	@media (max-width: $screen-sm-max) {
		.item {
			width: 100%;
			padding-bottom: 0;
			padding-top: 0;
		}
	}
}

.order_customer-proofreading {
	margin: 8px auto;
	max-width: 500px;
}

/**
 * Korektura
 */
.order_proofreading {
	padding-top: 36px !important;
	padding-bottom: 36px !important;
	.order_proofreading-content {
		margin: 0 auto;
		max-width: 84%;
	}
	.item {
		float: left;
		width: 50%;
		padding: 10px 20px;
	}
	li {
		position: relative;
		padding: 10px 0 10px 20px;
		&:before {
			@extend .fa-check;
			font-family: "FontAwesome";
			color: $cGreen;
			position: absolute;
			left: 0;
			top: 10px;
			display: block;
		}
	}

	@media (max-width: $screen-md-max) {
		.order_proofreading-content {
			max-width: 100%;
		}
	}
	@media (max-width: $screen-sm-max) {
		.item {
			float: none;
			width: 100%;
			padding-bottom: 0;
			+.item {
				padding-top: 0;
			}
		}
	}
}

/**
 * Rings color
 */
.order_rings-color {
	// Form elements
	.frm & {
		// iRadio
		.iRadio-container {
			margin: 8px 0;
		}
	}
}

/**
 * Mintage table preview
 */

.mintagePreviewTable {
	position: relative;
}

.mintagePreviewTable table {
	width: 100%;
	height: 720px;
}

.mintagePreviewTable tr {
	height: 70px;
}

.mintagePreviewTable tr td {
	text-align: center;
	font-size: 18px;
	font-weight: bold;
}

.mintagePreviewTable .mpt-spine {
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	transform: rotate(-90deg);
	-webkit-transform-origin: 50% 50%;
	-moz-transform-origin: 50% 50%;
	-ms-transform-origin: 50% 50%;
	-o-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
	position: absolute;
	left: 0px;
	width: 360px;
	height: 30px;
}

.mintagePreviewTable .mpt-spine-right {
	left: -150px;
	text-align: right;
	/*top: 180px;*/
}

.mintagePreviewTable .mpt-spine-left {
	left: -150px;
	text-align: left;
	top: 520px;
}

.mintagePreviewTable .mpt-partialCell {
	width: 43%;
}

.mintagePreviewTable .mpt-logo {
	height: 135px;
}

.company-select {
	width: 300px;
	float: left;
	padding-right: 20px;
}

.section-order-btn {
	@media (max-width: $screen-sm-max) {
		text-align: center;
	}
}