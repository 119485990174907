.annotation {
	display: block; float: left; @include fh(14px, 24px); color: $cOrange; font-weight: 500; padding: 0 6px; cursor: help; position: relative; margin: 7px 0;
	&.float-right {float: right;}
	.annotation-content {
		@include borderRadius(20px); @include fh(14px);
		display: block; position: absolute; right: -398px; top: -20px; border: 1px solid #888; padding: 12px 14px; z-index: 100; width: 380px; min-height: 60px; background: #fff; color: $cBase;
		display: none;
		&:before {
			content: ""; display: block; position: absolute; left: -21px; top: 15px; z-index: 110;
			width: 22px; height: 30px; background: url('../image/annotation-arrow.png') 0 0 no-repeat;
		}
		// Umistneni v levo
		&.annotation-content-left {
			right: auto; left: -398px;
			&:before {left: auto; right: -21px; @include rotate(180deg);}
		}
		// Obrazek
		&.annotation-content-image {
			@include borderRadius(52px);
			padding: 5px; top: -44px;
			&:before {top: 40px;}
			p {margin: 0 !important; padding: 0 !important;}
			p.annotation-thumb {
				float: left; width: 100px; @include fh(0em);
				img {@include image(); @include borderRadius(50px); border: 5px solid $cLineLight;}
			}
			p.annotation-txt {
				float: right; width: 268px; padding: 8px 18px 8px 12px !important;
			}
		}
	}
	&:hover .annotation-content {display: block;}
}