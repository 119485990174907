.pricelist-outer {
	position: relative;
	&:before {content: ""; position: absolute; left: 50%; top: 0; width: 1px; height: 100%; overflow: hidden; display: block; background: $cLine;}
	.left {
		float: left; width: 50%; padding: 0 50px 20px 0;
		._text {
			h2 {font-weight: 500; text-transform: none;}
			> h2:first-child {margin-top: 0;}
			table {
				th, td {width: 50%;}
				tbody th > div {
					position: relative; padding-right: 26px;
					.annotation {
						@include borderRadius(50%); @include fh(14px, 24px);
						float: none; position: absolute; right: -6px; top: -5px; margin: 0; color: $cBlue; background: #fff; width: 24px; text-align: center; font-weight: 700;
						* {font-weight: normal;}
						img {margin: 0;}
					}
				}
			}
		}
	}
	.right {
		> h2:first-child {margin-top: 0;}
		float: right; width: 50%; padding: 0 40px 0 50px;
		p.total-price {
			text-align: center; @include fh(16px);
			span {color: $cGreen;}
		}
		p.thumb {
			text-align: center; margin: 20px 0;
			a {display: inline-block; @include borderRadius(50%); border: 5px solid $cLineLight; margin: 0 8px;}
			img {@include image(); display: block; @include borderRadius(50%);}
		}
	}
	// Review
	.review-mini {
		margin: 20px 40px; text-align: center; padding-top: 20px;
		p.review-thumb {
			margin: 8px 0;
			a {display: inline-block;}
			img {@include image(); border: 1px solid $cLineLight; padding: 3px; @include borderRadius(6px);}
		}
		h3 {
			font-weight: 400; @include fh(16px, 1.4em);
			strong {color: $cOrange; font-weight: 700; display: block;}
		}
		p.txt {margin: 8px 0;}
	}
	@media (max-width: 780px) {
		&:before {
			display: none;
		}
		.left, .right {
			width: 100%;
		}
		.left {
			padding-right: 0;
		}
		.right {
			padding-right: 0;
			padding-left: 0;
			border-top: 1px solid $cLine;
			padding-top: 14px;
		}
	}
}


.prices-tabs {
	margin: 20px 0 20px;
	.label {
		font-weight: 700;
		padding-bottom: 4px;
	}
	.items {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: stretch;
		width: 100%;
		a {
			display: block;
			background: $cOrange;
			color: #fff;
			padding: 12px 12px 10px;
			margin: 0 1px 1px 0;
			&:hover, &.active {
				color: #fff;
				background: $cGreen;
			}
		}
	}
}