/* Version: 3.5.1 */
$imgUrl: "../image/select2.png";
$imgUrlSpinner: "../image/select2-spinner.gif";

.select2-locked {padding: 3px 5px 3px 5px !important;}
.select2-default {color: #999 !important;}
.select2-container,
.select2-drop,
.select2-search,
.select2-search input {@include boxSizing();}
.select2-container {
	margin: 0; position: relative; display: inline-block; zoom: 1; *display: inline; vertical-align: middle; text-align: left;
	.select2-choice {
		@include borderRadius(4px); @include boxSizing();
		display: block; height: 38px; padding: 0 0 0 8px; overflow: hidden; position: relative; border: 1px solid $cLine; white-space: nowrap; line-height: 38px; color: #444; 
		text-decoration: none; background-clip: padding-box; width: 100%;
		-webkit-touch-callout: none; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
		// background
		background-color: #fff;
		html[dir="rtl"] & {padding: 0 8px 0 0;}
		> .select2-chosen {
			margin-right: 20px; display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; float: none; width: auto;
			html[dir="rtl"] & {margin-left: 26px; margin-right: 0;}
			img {vertical-align: middle; margin-right: 4px; @include borderRadius(3px);}
		}
		abbr {
			display: none; width: 12px; height: 12px; position: absolute; right: 24px; top: 8px; font-size: 1px; text-decoration: none; border: 0; background: url($imgUrl) right top no-repeat; cursor: pointer; outline: 0;
			&:hover {background-position: right -11px; cursor: pointer;}
		}
		.select2-arrow {
			display: inline-block; width: 18px; height: 100%; position: absolute; right: 4px; top: 0; background-clip: padding-box;
			html[dir="rtl"] & {left: 0; right: auto;}
			b {
				display: block; width: 100%; height: 100%; background: url($imgUrl) no-repeat 0 3px;
				html[dir="rtl"] & {background-position: 2px 1px;}
			}
		}
	}
	&.select2-drop-above .select2-choice {
		@include borderRadius(0 0 4px 4px);
		border-bottom-color: $cLine; background: #fff;
	}
	&.select2-allowclear .select2-choice {
		.select2-chosen {margin-right: 42px;}
		abbr {display: inline-block;}
	}
	// Disabled
	&.select2-container-disabled .select2-choice {
		background-color: #f4f4f4; background-image: none; border: 1px solid $cLine; cursor: default;
		.select2-arrow {
			background-color: #f4f4f4; background-image: none; border-left: 0;
			abbr {display: none;}
		}
	}
}

.select2-drop-mask {
	@include opacity(0);
	border: 0; margin: 0; padding: 0; position: fixed; left: 0; top: 0; min-height: 100%; min-width: 100%; height: auto; width: auto; z-index: 9998; background-color: #fff;
}

.select2-drop {
	@include borderRadius(0 0 4px 4px); text-align: left;
	width: 100%; margin-top: -1px; position: absolute; z-index: 9999; top: 100%; background: #fff; color: #000; border: 1px solid $cLine; border-top: 0;
	&._select2-border-blue {border-color: $cBlue;}
	// Zobrazi se nahoru
	&.select2-drop-above {
		@include borderRadius(4px 4px 0 0);
		margin-top: 1px; border-top: 1px solid $cLine; border-bottom: 0;
		.select2-search input {margin-top: 4px;}
	}
	/*&.select2-drop-active {
		border: 1px solid #5897fb; border-top: none;
		&.select2-drop-above {border-top: 1px solid #5897fb;}
	}*/
}

.select2-drop-auto-width {
	border-top: 1px solid $cLine; width: auto;
	.select2-search {padding-top: 4px;}
}

.select2-search {
	display: inline-block; width: 100%; min-height: 26px; margin: 0; padding-left: 4px; padding-right: 4px; position: relative; z-index: 10000; white-space: nowrap;
	input {
		@include borderRadius(0px);
		width: 100%; height: auto !important; min-height: 26px; padding: 4px 20px 4px 5px; margin: 0; outline: 0; font-family: sans-serif; font-size: 1em; border: 1px solid $cLine;
		background: #fff url($imgUrl) no-repeat 100% -22px;
		background: url($imgUrl) no-repeat 100% -22px, -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
		background: url($imgUrl) no-repeat 100% -22px, -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
		background: url($imgUrl) no-repeat 100% -22px, -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
		background: url($imgUrl) no-repeat 100% -22px, linear-gradient(to bottom, #fff 85%, #eee 99%) 0 0;
		html[dir="rtl"] & {
			padding: 4px 5px 4px 20px;
			background: #fff url($imgUrl) no-repeat -37px -22px;
			background: url($imgUrl) no-repeat -37px -22px, -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
			background: url($imgUrl) no-repeat -37px -22px, -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
			background: url($imgUrl) no-repeat -37px -22px, -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
			background: url($imgUrl) no-repeat -37px -22px, linear-gradient(to bottom, #fff 85%, #eee 99%) 0 0;
		}
	}
	&.select2-active {
		background: #fff url($imgUrlSpinner) no-repeat 100%;
		background: url($imgUrlSpinner) no-repeat 100%, -webkit-gradient(linear, left bottom, left top, color-stop(0.85, #fff), color-stop(0.99, #eee));
		background: url($imgUrlSpinner) no-repeat 100%, -webkit-linear-gradient(center bottom, #fff 85%, #eee 99%);
		background: url($imgUrlSpinner) no-repeat 100%, -moz-linear-gradient(center bottom, #fff 85%, #eee 99%);
		background: url($imgUrlSpinner) no-repeat 100%, linear-gradient(to bottom, #fff 85%, #eee 99%) 0 0;
	}
}

.select2-container-active .select2-choice, .select2-container-active .select2-choices {
	//border: 1px solid #5897fb; 
	outline: none;
}

.select2-dropdown-open {
	.select2-choice {
		border-bottom-color: transparent; border-bottom-left-radius: 0; border-bottom-right-radius: 0; background-color: #fff;
		.select2-arrow {
			background: transparent; border-left: none; filter: none;
			html[dir="rtl"] & {border-right: none;}
			b {
				background-position: -18px 1px;
				html[dir="rtl"] & {background-position: -16px 1px;}
			}
		}
	}
	&.select2-drop-above {
		.select2-choice, .select2-choices {
			//border: 1px solid #5897fb; 
			border-top-color: transparent;
		}
	}
}

// Neaktivni select
.select2-hidden-accessible {border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;}

// Vysledek
.select2-results {
	max-height: 200px; padding: 0 0 0 4px;  margin: 4px 4px 4px 0; position: relative; overflow-x: hidden; overflow-y: auto; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	html[dir="rtl"] & {padding: 0 4px 0 0; margin: 4px 0 4px 4px;}
	.select2-result-label {
		padding: 3px 7px 4px; margin: 0; cursor: pointer; min-height: 1em;
		-webkit-touch-callout: none; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
		img {vertical-align: middle; margin-right: 4px; @include borderRadius(3px);}
	}
	ul.select2-result-sub {
		margin: 0;padding-left: 0;
	}
	li {
		list-style: none; display: list-item; background-image: none;
		&.select2-result-with-children > .select2-result-label {font-weight: bold;}
		em {background: #feffde; font-style: normal;}
	}
	.select2-highlighted {
		background: $cBlue;color: #fff;
		em {background: transparent;}
		ul {background: #fff; color: #000;}
	}
	.select2-no-results, .select2-searching, .select2-ajax-error, .select2-selection-limit {background: #f4f4f4; display: list-item; padding-left: 5px;}
	// Disabled
	.select2-disabled {
		background: #f4f4f4; display: list-item; cursor: default;
		&.select2-highlighted {color: #666; background: #f4f4f4; display: list-item; cursor: default;}
	}
	// Selected
	.select2-selected {display: none;}
	// Error
	.select2-ajax-error {background: rgba(255, 50, 50, .2);}
}
.select2-results-dept-1 .select2-result-label { padding-left: 20px }
.select2-results-dept-2 .select2-result-label { padding-left: 40px }
.select2-results-dept-3 .select2-result-label { padding-left: 60px }
.select2-results-dept-4 .select2-result-label { padding-left: 80px }
.select2-results-dept-5 .select2-result-label { padding-left: 100px }
.select2-results-dept-6 .select2-result-label { padding-left: 110px }
.select2-results-dept-7 .select2-result-label { padding-left: 120px }

.select2-more-results {
	background: #f4f4f4; display: list-item;
	.select2-active {background: #f4f4f4 url('select2-spinner.gif') no-repeat 100%;}
}

// Multiselect
.select2-container-multi {
	.select2-choices {
		height: auto !important; height: 1%; margin: 0; padding: 0 5px 0 0; position: relative; border: 1px solid $cLine; cursor: text; overflow: hidden; min-height: 26px;
		background-color: #fff;
		background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, color-stop(1%, #eee), color-stop(15%, #fff));
		background-image: -webkit-linear-gradient(top, #eee 1%, #fff 15%);
		background-image: -moz-linear-gradient(top, #eee 1%, #fff 15%);
		background-image: linear-gradient(to bottom, #eee 1%, #fff 15%);
		html[dir="rtl"] & {padding: 0 0 0 5px;}
		li {
			float: left; list-style: none;
			html[dir="rtl"] & {float: right;}
		}
		// Vyhledavaci pole
		.select2-search-field {
			margin: 0; padding: 0; white-space: nowrap;
			input {
				padding: 5px; margin: 1px 0; font-family: sans-serif; font-size: 100%; color: #666; outline: 0; border: 0; background: transparent !important;
				&.select2-active {background: #fff url('select2-spinner.gif') no-repeat 100% !important;}
			}
		}
		// Vysledky vyhledavani
		.select2-search-choice {
			@include borderRadius(3px);
			padding: 3px 5px 3px 18px; margin: 3px 0 3px 5px; position: relative; line-height: 13px; color: #333; cursor: default; border: 1px solid $cLine;
			background-clip: padding-box; -webkit-touch-callout: none; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none;
			background-color: #e4e4e4;
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#f4f4f4', GradientType=0);
			background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, color-stop(20%, #f4f4f4), color-stop(50%, #f0f0f0), color-stop(52%, #e8e8e8), color-stop(100%, #eee));
			background-image: -webkit-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
			background-image: -moz-linear-gradient(top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
			background-image: linear-gradient(to top, #f4f4f4 20%, #f0f0f0 50%, #e8e8e8 52%, #eee 100%);
			html[dir="rtl"] & {margin: 3px 5px 3px 0; padding: 3px 18px 3px 5px;}
			.select2-chosen {cursor: default;}
			.select2-search-choice-close:hover {background-position: right -11px;}
		}
		// Hover
		.select2-search-choice-focus {
			background: #d4d4d4;
			.select2-search-choice-close {background-position: right -11px;}
		}
	}
	&.select2-container-active .select2-choices {
		//border: 1px solid #5897fb; 
		outline: none;
	}
	// Odstraneni moznosti
	.select2-search-choice-close {
		left: 3px;
		html[dir="rtl"] & {left: auto; right: 2px;}
	}
	// Disabled
	&.select2-container-disabled .select2-choices {
		background-color: #f4f4f4; background-image: none; border: 1px solid $cLine; cursor: default;
		.select2-search-choice {
			padding: 3px 5px 3px 5px; border: 1px solid $cLine; background-image: none; background-color: #f4f4f4;
			.select2-search-choice .select2-search-choice-close {display: none; background: none;}
		}
	}
}

// Odstraneni moznosti
.select2-search-choice-close {
	display: block; width: 12px; height: 13px; position: absolute; right: 3px; top: 4px; font-size: 1px; outline: none; background: url($imgUrl) right top no-repeat;
	html[dir="rtl"] & {right: auto; left: 3px;}
}

// Ostatni
.select2-result-selectable .select2-match, .select2-result-unselectable .select2-match {text-decoration: underline;}
.select2-offscreen, .select2-offscreen:focus {clip: rect(0 0 0 0) !important; width: 1px !important; height: 1px !important; border: 0 !important; margin: 0 !important; padding: 0 !important; overflow: hidden !important; position: absolute !important; outline: 0 !important; left: 0px !important; top: 0px !important;}
.select2-display-none {display: none;}
.select2-measure-scrollbar {position: absolute; top: -10000px; left: -10000px; width: 100px; height: 100px; overflow: scroll;}
// Retina-ize icons
//@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 2dppx)  {.select2-search input, .select2-search-choice-close, .select2-container .select2-choice abbr, .select2-container .select2-choice .select2-arrow b {background-image: url('select2x2.png') !important; background-repeat: no-repeat !important; background-size: 60px 40px !important;} .select2-search input {background-position: 100% -21px !important;}}

// EDITACE
._selectSearchInput .select2-search {display: block;}
